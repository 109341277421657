import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { BackgroundType, CreateJourneyInput, UpdateBookingPageInput } from '../../../API';
import { PublicBookingPageContainer } from '../../publicBookingPage';
import labels from './labels';
import { BACKGROUND_OPTIONS, BackgroundOption } from '../../../store/bookingPages';
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  ArrowUturnLeftIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { TabPanel, TabView } from 'primereact/tabview';
import { MAX_LENGTH_ABOUT_300_WORDS } from '../../../types/constants';
import { Dropdown } from 'primereact/dropdown';
import {
  convertBackgroundOptionToType,
  convertBackgroundTypeToOption,
  getBackgroundLabelbyType,
} from '../../../store/bookingPages/utils';
import { RadioButton } from 'primereact/radiobutton';
import { ReactComponent as BackgroundTopIcon } from '../../../assets/icons/bookingPage/backgroundTop.svg';
import { ReactComponent as BackgroundFullIcon } from '../../../assets/icons/bookingPage/backgroundFull.svg';
import { ReactComponent as BackgroundLeftIcon } from '../../../assets/icons/bookingPage/backgroundLeft.svg';
import { ReactComponent as BackgroundRightIcon } from '../../../assets/icons/bookingPage/backgroundRight.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/rootStore';
import { eventActions, eventSelectors, EventSteps } from '../../../store/publicBookingPage';
import { ColorPickerInput } from '../../common';
import { InputTextarea } from 'primereact/inputtextarea';
import { ImageUpload } from '../../common/imageUpload/ImageUpload';
import { workspacesSelectors } from '../../../store/workspaces';

type IBookingPagePreviewModal = {
  visible: boolean;
  journey?: CreateJourneyInput;
  bookingPage: UpdateBookingPageInput;
  isReadOnly?: boolean;
  isBookingTemplate?: boolean;
  onSave: (value?: UpdateBookingPageInput) => void;
  onClose: () => void;
};

export const BookingPagePreviewModal = ({
  visible,
  journey,
  bookingPage,
  isReadOnly,
  isBookingTemplate,
  onSave,
  onClose,
}: IBookingPagePreviewModal) => {
  const dispatch = useDispatch<AppDispatch>();
  const previewBookingPage = useSelector(eventSelectors.selectBookingPage);
  const previewStyle = useSelector(eventSelectors.selectStyle);
  const previewLabels = useSelector(eventSelectors.selectLabels);
  const logo = useSelector(eventSelectors.selectLogo);
  const background = useSelector(eventSelectors.selectBackground);
  const backgroundType = useSelector(eventSelectors.selectBackgroundType);
  const backgroundColor = useSelector(eventSelectors.selectBackgroundColor);
  const primaryColor = useSelector(eventSelectors.selectPrimaryColor);
  const css = useSelector(eventSelectors.selectCss);
  const footerHtml = useSelector(eventSelectors.selectFooterHtml);
  const step = useSelector(eventSelectors.selectStep);
  const currentWorkspace = useSelector(workspacesSelectors.selectCurrentWorkspaceData);

  const [backgroundOption, setBackgroundOption] = useState(
    convertBackgroundTypeToOption(bookingPage.style?.backgroundType)
  );
  const [maximized, setMaximized] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (step === EventSteps.JOURNEY) {
      setStepIndex(0);
    } else if (step === EventSteps.WHEN) {
      setStepIndex(1);
    } else if (step === EventSteps.INFO) {
      setStepIndex(2);
    } else if (step === EventSteps.BOOKED) {
      if (stepIndex !== 3 && stepIndex !== 4 && stepIndex !== 6) {
        setStepIndex(3);
      }
    } else if (step === EventSteps.CANCEL) {
      setStepIndex(5);
    }
  }, [step]);

  const handleBackgroundOptionChange = (value: BackgroundOption) => {
    setBackgroundOption(value);
    dispatch(eventActions.updatePreviewStyle({ backgroundType: convertBackgroundOptionToType(value) }));
  };

  const handleBackgroundTypeChange = (backgroundType: BackgroundType) => {
    dispatch(eventActions.updatePreviewStyle({ backgroundType }));
  };

  const handleLogoChange = (logoImage: string | null) => {
    dispatch(eventActions.updatePreviewStyle({ logoImage }));
  };

  const handleBackgroundChange = (backgroundImage: string | null) => {
    dispatch(eventActions.updatePreviewStyle({ backgroundImage }));
  };

  const handleBackgroundColorChange = (backgroundColor: string) => {
    dispatch(eventActions.updatePreviewStyle({ backgroundColor }));
  };

  const handlePrimaryColorChange = (primaryColor: string) => {
    dispatch(eventActions.updatePreviewStyle({ primaryColor }));
  };

  const handleCssChange = (css: string) => {
    dispatch(eventActions.updatePreviewStyle({ css }));
  };

  const handleFooterChange = (footerHtml: string) => {
    dispatch(eventActions.updatePreviewStyle({ footerHtml }));
  };

  const handleNavigationTabChange = (index: number) => {
    if (index === 0) {
      dispatch(eventActions.setEventStep(EventSteps.JOURNEY));
    } else if (index === 1) {
      dispatch(eventActions.setEventStep(EventSteps.WHEN));
    } else if (index === 2) {
      dispatch(eventActions.setEventStep(EventSteps.INFO));
    } else if (index === 3 || index === 4 || index === 6) {
      dispatch(eventActions.setEventStep(EventSteps.BOOKED));
    } else if (index === 5) {
      dispatch(eventActions.setEventStep(EventSteps.CANCEL));
    }
    dispatch(eventActions.setIsRescheduled(index === 4));
    dispatch(eventActions.updateCanceled({ isCanceled: index === 6 }));
    setStepIndex(index);
  };

  const handleResetStyles = () => {
    dispatch(eventActions.updatePreviewStyle({ ...currentWorkspace?.style }));
    dispatch(eventActions.updatePreviewLabel({ ...currentWorkspace?.labels }));
    setBackgroundOption(convertBackgroundTypeToOption(currentWorkspace?.style?.backgroundType));
  };

  const generateBackgroundIcon = (type: BackgroundType, isActive: boolean) => {
    switch (type) {
      case BackgroundType.TOP:
        return <BackgroundTopIcon className={isActive ? 'text-blue-main' : 'text-heavy-10'} />;
      case BackgroundType.FULL:
        return <BackgroundFullIcon className={isActive ? 'text-blue-main' : 'text-heavy-10'} />;
      case BackgroundType.LEFT:
        return <BackgroundLeftIcon className={isActive ? 'text-blue-main' : 'text-heavy-10'} />;
      case BackgroundType.RIGHT:
        return <BackgroundRightIcon className={isActive ? 'text-blue-main' : 'text-heavy-10'} />;
      default:
        return <></>;
    }
  };

  const generateBackgroundTypeSelector = () => (
    <div className="flex-between">
      {[BackgroundType.TOP, BackgroundType.FULL, BackgroundType.LEFT, BackgroundType.RIGHT].map((type) => (
        <div
          key={type}
          className="flex flex-column gap-10px cursor-pointer"
          onClick={() => handleBackgroundTypeChange(type)}
        >
          <RadioButton checked={type === backgroundType} />
          <div className="flex-left-center flex-column gap-6px">
            {generateBackgroundIcon(type, type === backgroundType)}
            <div className="text-label-xs-reg text-heavy-80">{getBackgroundLabelbyType(type)}</div>
          </div>
        </div>
      ))}
    </div>
  );

  const generateSettingsSection = () => (
    <div className="flex flex-column gap-32px px-20px pt-12px pb-20px">
      <div className="text-body-s-reg text-heavy-60">
        {isBookingTemplate ? labels.descriptionBookingTemplate : labels.descriptionBookingPage}
      </div>

      <div className="flex flex-column gap-10px">
        <div className="text-title-xs-med">{labels.logo}</div>
        <ImageUpload value={logo} disabled={isReadOnly} onChange={handleLogoChange} />
      </div>

      <div className="border-top-1 border-heavy-20"></div>
      <div className="flex flex-column gap-10px">
        <div className="text-title-xs-med">{labels.background}</div>
        <Dropdown
          options={BACKGROUND_OPTIONS}
          value={backgroundOption}
          onChange={(e) => handleBackgroundOptionChange(e.value)}
        />
        {backgroundOption === BackgroundOption.WALLPAPER && (
          <div className="flex flex-column gap-24px">
            <ImageUpload value={background} disabled={isReadOnly} onChange={handleBackgroundChange} />
            <div className="flex flex-column gap-12px">
              <div className="text-title-xs-med">{labels.backgroundType}</div>
              {generateBackgroundTypeSelector()}
            </div>
          </div>
        )}
        {backgroundOption === BackgroundOption.COLOR && (
          <ColorPickerInput
            label={labels.backgroundColor}
            value={backgroundColor || ''}
            onChange={handleBackgroundColorChange}
            disabled={isReadOnly}
          />
        )}
      </div>

      <div className="border-top-1 border-heavy-20"></div>
      <ColorPickerInput
        label={labels.mainColor}
        value={primaryColor || ''}
        onChange={handlePrimaryColorChange}
        disabled={isReadOnly}
      />

      <div className="border-top-1 border-heavy-20"></div>
      <div className="flex flex-column gap-10px">
        <div className="text-title-xs-med">{labels.customCss}</div>
        <InputTextarea
          placeholder={labels.customCssPlaceholder}
          autoResize
          rows={2}
          value={css || ''}
          onChange={(e) => handleCssChange(e.target.value.trimStart())}
          maxLength={MAX_LENGTH_ABOUT_300_WORDS}
          disabled={isReadOnly}
        />
      </div>

      <div className="border-top-1 border-heavy-20"></div>
      <div className="flex flex-column gap-10px">
        <div className="text-title-xs-med">{labels.footerHtml}</div>
        <InputTextarea
          placeholder={labels.footerHtmlPlaceholder}
          autoResize
          rows={2}
          value={footerHtml || ''}
          onChange={(e) => handleFooterChange(e.target.value.trimStart())}
          maxLength={MAX_LENGTH_ABOUT_300_WORDS}
          disabled={isReadOnly}
        />
      </div>
    </div>
  );

  const generateNavigationPanel = () => (
    <div className="flex-none flex-between-center px-20px border-bottom-1 border-heavy-20">
      <div className="action-button" onClick={() => setMaximized((prev) => !prev)}>
        {maximized ? <ArrowsPointingInIcon className="icon-20px" /> : <ArrowsPointingOutIcon className="icon-20px" />}
      </div>
      <TabView
        className="pt-8px -mb-1px"
        activeIndex={stepIndex}
        onTabChange={(e) => handleNavigationTabChange(e.index)}
      >
        <TabPanel header={labels.stepJourney} disabled={!journey?.id} />
        <TabPanel header={labels.stepBookingPage} />
        <TabPanel header={labels.stepBookingForm} />
        <TabPanel header={labels.stepBooked} />
        <TabPanel header={labels.stepRescheduled} />
        <TabPanel header={labels.stepCancel} />
        <TabPanel header={labels.stepCanceled} />
      </TabView>
      <div>
        {maximized && (
          <div className="action-button" onClick={onClose}>
            <XMarkIcon className="icon-20px" />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Dialog
      visible={visible}
      className="w-full h-screen max-w-full max-h-full"
      maskClassName="z-overlap-chat"
      showHeader={false}
      contentClassName="p-0 border-noround"
      onHide={onClose}
      resizable={false}
      focusOnShow={false}
    >
      <div className="flex flex-column h-full">
        {!maximized && (
          <div className="flex-between-center py-14px pl-20px pr-10px border-bottom-1 border-heavy-20">
            <div className="text-title-lg-med ">{labels.title}</div>
            <div className="action-button" onClick={onClose}>
              <XMarkIcon className="icon-20px" />
            </div>
          </div>
        )}

        <div className="flex-1 flex overflow-y-hidden">
          {!maximized && (
            <div className="w-400px border-right-1 border-heavy-10 overflow-y-auto">{generateSettingsSection()}</div>
          )}
          <div className="flex-1 bg-primary-white overflow-hidden">
            <div className="overflow-auto h-full">
              <div className="flex flex-column h-full w-fit min-w-full">
                {generateNavigationPanel()}
                <div className="flex-1 relative overflow-hidden">
                  <PublicBookingPageContainer
                    previewJourney={journey}
                    previewBookingPage={bookingPage}
                    isReadOnly={isReadOnly}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-between z-1 py-12px px-20px border-top-1 border-heavy-20 bg-primary-white">
          <div className="flex-left-center gap-6px">
            {!isReadOnly && (
              <Button
                className="button-xl min-w-120px"
                label={labels.save}
                onClick={() =>
                  onSave({
                    ...previewBookingPage,
                    style: previewStyle,
                    labels: previewLabels,
                  } as UpdateBookingPageInput)
                }
              />
            )}
            <Button className="button-xl" text label={labels.cancel} onClick={onClose} />
          </div>
          {!isReadOnly && (
            <Button className="button-xl gap-8px" text onClick={handleResetStyles}>
              <ArrowUturnLeftIcon className="icon-18px" />
              <div>{labels.resetDefaultStyles}</div>
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

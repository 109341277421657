import { useDispatch, useSelector } from 'react-redux';
import { bookingPageSelectors, bookingPagesActions } from '../../../../../store/bookingPages';
import {
  DURATION_TIME_UNIT_OPTIONS,
  MIN_DURATION_COUNT,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
} from '../../../../../store/bookingTemplates';
import { TimeUnit } from '../../../../../API';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import labels from './labels';
import { BUFFER_BLOCK_GAP, BUFFER_BLOCK_HEIGHT, NONE_VALUE, TIME_OPTIONS } from './constants';
import { SumoTooltip } from '../../../sumoTooltip/SumoTooltip';
import { InputSwitch } from 'primereact/inputswitch';
import { useState } from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';

type DurationSettingsProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly: boolean;
};

export const DurationSettings = ({ selectors, actions, isReadOnly }: DurationSettingsProps) => {
  const dispatch = useDispatch();
  const durationCount = useSelector(selectors.selectDurationCount);
  const durationTimeUnit = useSelector(selectors.selectDurationTimeUnit);
  const isDurationCountValid = useSelector(selectors.selectIsDurationCountValid);
  const beforeStartTime = useSelector(selectors.selectTimeBeforeStartTime);
  const afterEndTime = useSelector(selectors.selectTimeAfterEndTime);
  const durationMinutes = useSelector(selectors.selectDurationMinutes);
  const beforeBufferMinutes = useSelector(selectors.selectBeforeBufferMinutes);
  const afterBufferMinutes = useSelector(selectors.selectAfterBufferMinutes);

  const [isBufferActive, setIsBufferActive] = useState(Boolean(beforeStartTime || afterEndTime));

  const handleDurationCountChange = (value: number | null | undefined) => {
    dispatch(actions.updateWhenDuration({ count: value }));
  };

  const handleTimeUnitChange = (timeUnit: TimeUnit) => {
    dispatch(actions.updateWhenDuration({ timeUnit }));
  };

  const handleBuffersChange = (active: boolean) => {
    setIsBufferActive(active);
    if (!active) {
      dispatch(actions.updateWhenStep({ beforeStartTime: null, afterEndTime: null }));
    }
  };

  const handleBeforeSelectChange = (value: string) => {
    const beforeStartTime = value === NONE_VALUE ? null : value;
    dispatch(actions.updateWhenStep({ beforeStartTime }));
  };

  const handleAfterSelectChange = (value: string) => {
    const afterEndTime = value === NONE_VALUE ? null : value;
    dispatch(actions.updateWhenStep({ afterEndTime }));
  };

  const getTotalHeight = () =>
    BUFFER_BLOCK_HEIGHT - BUFFER_BLOCK_GAP * ((beforeStartTime ? 1 : 0) + (afterEndTime ? 1 : 0));
  const getBeforeHeight = () =>
    (getTotalHeight() * beforeBufferMinutes) / (beforeBufferMinutes + durationMinutes + afterBufferMinutes);
  const getDurationHeight = () =>
    (getTotalHeight() * durationMinutes) / (beforeBufferMinutes + durationMinutes + afterBufferMinutes);
  const getAfterHeight = () =>
    (getTotalHeight() * afterBufferMinutes) / (beforeBufferMinutes + durationMinutes + afterBufferMinutes);

  return (
    <div className="flex flex-column gap-32px">
      <div className="flex flex-column gap-4px">
        <div className="text-title-xs-med text-heavy-100">{labels.durationTitle}</div>
        <div className="text-body-s-reg text-heavy-60">{labels.durationDescription}</div>
        <div className="flex-left-center gap-4px pt-6px">
          <InputNumber
            className={`w-90px ${!isDurationCountValid && 'p-invalid'}`}
            inputClassName="w-full"
            showButtons
            min={MIN_DURATION_COUNT}
            max={durationTimeUnit === TimeUnit.MINUTE ? 720 : 12}
            value={durationCount}
            onValueChange={(e) => handleDurationCountChange(e.target.value)}
            disabled={isReadOnly}
          ></InputNumber>
          <Dropdown
            value={durationTimeUnit}
            onChange={(e) => handleTimeUnitChange(e.target.value)}
            options={DURATION_TIME_UNIT_OPTIONS}
            optionLabel="label"
            disabled={isReadOnly}
          />
        </div>
      </div>

      <div className="flex gap-10px">
        <InputSwitch
          checked={isBufferActive}
          onChange={(e) => handleBuffersChange(Boolean(e.value))}
          disabled={isReadOnly}
        />
        <div className="flex flex-column gap-16px pt-4px">
          <div className="flex-left-center gap-6px">
            <div className="text-title-xs-med text-heavy-100">{labels.bufferTitle}</div>
            <SumoTooltip text={labels.bufferTooltip} />
          </div>
          {isBufferActive && (
            <div className="flex-left-center gap-12px">
              <div className="flex flex-column gap-4px bg-heavy-1 border-radius-8px py-6px px-10px w-144px h-88px">
                <div className="bg-striped border-radius-4px" style={{ height: getBeforeHeight() }} />
                <div
                  className="bg-primary-white border-radius-4px flex-center text-blue-main shadow-level-1 z-1"
                  style={{ height: getDurationHeight() }}
                >
                  <CalendarIcon className="icon-18px" style={{ maxHeight: 'calc(100% + 8px)' }} />
                </div>
                <div className="bg-striped border-radius-4px" style={{ height: getAfterHeight() }} />
              </div>

              <div className="flex-between flex-column h-full text-label-input-reg text-heavy-80">
                <div className="flex-left-center gap-10px">
                  <Dropdown
                    className="w-144px"
                    value={beforeStartTime || NONE_VALUE}
                    options={TIME_OPTIONS}
                    optionLabel="label"
                    onChange={(e) => handleBeforeSelectChange(e.target.value)}
                    disabled={isReadOnly}
                  />
                  <div>{labels.beforeCheckboxLabel}</div>
                </div>
                <div className="flex-left-center gap-10px">
                  <Dropdown
                    className="w-144px"
                    value={afterEndTime || NONE_VALUE}
                    options={TIME_OPTIONS}
                    optionLabel="label"
                    onChange={(e) => handleAfterSelectChange(e.target.value)}
                    disabled={isReadOnly}
                  />
                  <div>{labels.afterCheckboxLabel}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DurationSettings.displayName = 'DurationSettings';

export const PublicBookingPage = Object.freeze({
  HOST: `Host`,
  HOST_PHONE: `Host phone`,
  EDIT_BUTTON: `Edit`,
  MORE: `More`,
  TIME_ZONE_LABEL: `Time Zone`,
  NAME_FIELD_LABEL: `Name`,
  EMAIL_FIELD_LABEL: `Email`,
  INVITE_OTHERS_FIELD_LABEL: `Additional Attendees`,
  INVITE_OTHERS_FIELD_PLACEHOLDER: `Invite others by entering their email addresses separated by commas`,
  NOTE_FOR_ATTENDEES_FIELD_LABEL: `Note for Attendees`,
  NOTE_FOR_ATTENDEES_FIELD_PLACEHOLDER: `Enter a note for all attendees`,
  NO_USERS_AVAILABLE_LABEL: `There are no available users at the selected time. Please select another time.`,
  CANCEL_TYPE_LABEL: `Why cancel?`,
  CANCEL_REASON_LABEL: `Cancel reason:`,
  IN_PERSON_LABEL: `In-Person`,
  PHONE_LABEL: `Phone`,
  GOOGLE_MEET: `Google Meet`,
  ZOOM: `Zoom`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  ERROR: `Error`,
  BACK_TO_HOME: `Back to home`,
  VIDEO_CONFERENCE_LABEL: `Video Conference`,
  SLOTS_NO_FOUND: `Slots not found`,
  VIEW_NEXT_MONTH: `View next month`,
  ALLOW_SMS: `Receive appointment reminders via text?`,
  BOOKING_PAGE_EXPIRED: `This booking page is expired. Please request a new booking link.`,
  PERSONAL_INFO: `Personal Info`,
  ATTENDEE_EMAIL: `Attendee email`,
  ADD_ATTENDEES: `Add attendees`,
  YOUR_ANSWERS: `Your answers`,
  MANAGE_BOOKING: `Manage booking`,
  MODIFY_BOOKING: `Modify Booking`,
  CANCEL_POLICY: `Cancel Policy`,
  LOCATIONS_PLACEHOLDER: `Enter address`,
  LOADING_LABEL_1: `Creating meeting`,
  LOADING_LABEL_2: `Syncing calendars`,
  LOADING_LABEL_3: `Sending messages`,
  MILES: ` miles`,
  KILOMETERS: ` kilometers`,
  LEARN_MORE: `Learn more`,
  LOCATION_ACCESS_TOOLTIP: `Cannot access your location `,
});

export const BookedMeetings = Object.freeze({
  ADD_NOTE: `Add note`,
  ATTENDEES: `Attendees`,
  CANCELED: 'Canceled',
  CANCEL_MEETING_TITLE: `Cancel Meeting`,
  CANCEL_MEETING_DESCRIPTION: `Why cancel?`,
  CANCEL_REASON: `Cancel reason:`,
  DETAILS: `Details`,
  BOOKED: `Booked`,
  BOOKING_PAGE: `Booking page`,
  BOOKING_PAGE_PLACEHOLDER: `Select Booking page`,
  EDIT_ATTENDEE_LIMIT: `Edit Attendee Limit`,
  GOOGLE_MEET: `Google Meet`,
  GUEST: `Guest`,
  GUEST_ANSWERS: `Guest answers`,
  HOSTS: `Hosts`,
  IN_PERSON: `In Person`,
  LOCATION_TITLE: `Location`,
  NO_SCHEDULED_MEETINGS: `You have no booked meetings`,
  NO_LOCATION_GIVEN: `No location given`,
  NO_SHOW_LABEL: `No Show`,
  MARK_AS_NO_SHOW: `Mark as No-Show`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART1: `Are you sure you want to mark`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART2: `as a no-show?`,
  MARK_AS_NO_SHOW_TOOLTIP: `The meeting has not started yet.`,
  MEETING: `Meeting`,
  MEETINGS: `Meetings`,
  INTERNAL_MEETING_NOTES: `Internal meeting note`,
  INTERNAL_MEETING_NOTES_DESC: `Only the host will see these note`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  NOTE_FROM_INVITEE: `Note from invitee`,
  NO_ANSWERS: `No Answers`,
  NO_ATTENDEES: `No Attendees`,
  NO_NOTE: `No Note`,
  PAST: `Past`,
  PHONE_CALL: `Phone Call`,
  RESCHEDULE: `Reschedule`,
  REASON_UNEXPECTED: `Unexpected conflict`,
  REASON_INVITE: `Need to invite others`,
  REASON_TIME: `Need time to prepare`,
  REASON_OTHER: `Other`,
  STATUS: `Status`,
  STATUS_PLACEHOLDER: `Select Status`,
  TEAM: `Team`,
  TITLE: `Booked Meetings`,
  THIS_MONTH: `This Month`,
  UNDO_NO_SHOW: `Undo No-Show`,
  UPCOMING: `Upcoming`,
  ZOOM: `Zoom`,
  VIDEO_CONFERENCE: `Video Conference`,
  WORKSPACE: `Workspace`,
});

export const BookedMeetingsToastsNotifications = Object.freeze({
  GET_SCHEDULED_MEETINGS_ERROR_TOAST: `Get booked meeting request fail`,
  CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE: `Cancel meeting request fail`,
  CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Meeting successfully canceled`,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST: `Update meeting request fail`,
  DELETE_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Meeting successfully deleted`,
  DELETE_SCHEDULED_MEETING_ERROR_MESSAGE: `Delete meeting request fail`,
});

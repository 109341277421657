import { useSelector } from 'react-redux';
import { GlobalError, Preloader } from '../components/common';
import { authenticationActions, authenticationSelectors } from '../store/authentication';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorActions } from '../store/error/actions';
import { ErrorCodes } from '../store/error/types';
//import Layout from '../components/private/auth/layout';
import { Outlet, useLocation, useOutlet } from 'react-router-dom';
import { opsConsoleSelectors } from '../store/opsConsole/common/selectors';
import { opsConsoleActions } from '../store/opsConsole/common/actions';
import { UserSettingsKeys } from '../store/userSettings';
import { ViewAsUserInfoModal } from '../components/opsConsole';
import { globalActions, globalSelectors } from '../store/global';
import { errorSelectors } from '../store/error/selectors';
import { useSession } from '../hooks/useSession';
import { Path } from './pathes';
import { RainbowLayout } from '../components/private/auth/RainbowLayout';

export const OPSConsoleRoute = () => {
  const dispatch = useDispatch();
  const outlet = useOutlet();
  const location = useLocation();
  const { isAuthenticated } = useSession();
  const authenticationDataReceived = useSelector(authenticationSelectors.selectAuthenticationDataReceived);

  const isFetching = useSelector(authenticationSelectors.selectIsFetching);
  const isSumo1Admin = useSelector(authenticationSelectors.selectIsSumo1Admin);
  const staffUserId = useSelector(authenticationSelectors.selectSumo1AdminUserId);
  const paths = useSelector(opsConsoleSelectors.selectPaths);
  const isMainDataError = useSelector(globalSelectors.selectIsError); // TODO: reneame or change the logic
  const hasError = useSelector(errorSelectors.selectHasError);
  const searchPaths = useSelector(globalSelectors.selectSearchPaths);
  const startOnes = useRef(false);

  const [isViewAsUserMode, setIsViewAsUserMode] = useState(false);
  const [workspaceId, setWorkspaceId] = useState(localStorage.getItem(UserSettingsKeys.WORKSPACE_ID));

  useEffect(() => {
    if (paths && !paths.some((name) => location.pathname.startsWith(name))) {
      dispatch(opsConsoleActions.resetFiltering());
    }

    if (searchPaths && !searchPaths.some((name) => location.pathname.startsWith(name))) {
      dispatch(globalActions.resetSearch());
    }
  }, [location]);

  useEffect(() => {
    // check if currect user is not the same user from ViewAsUserMode
    const handler = setInterval(() => {
      const userId = localStorage.getItem(UserSettingsKeys.USER_ID);
      // worskpaceId - to detect if all main data loaded
      const workspaceId = localStorage.getItem(UserSettingsKeys.WORKSPACE_ID);
      setWorkspaceId(workspaceId);

      if (userId && staffUserId) {
        if (userId !== staffUserId) setIsViewAsUserMode(true);
        else if (workspaceId) {
          setIsViewAsUserMode(false);
        }
      }
    }, 2000);
    return () => {
      clearInterval(handler);
    };
  }, []);

  useEffect(() => {
    if (!isFetching && !isSumo1Admin) {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, [isFetching]);

  useEffect(() => {
    if (
      isAuthenticated &&
      !authenticationDataReceived &&
      !isMainDataError &&
      !startOnes.current &&
      !location.pathname.startsWith('/opsconsole/view-as-user/')
    ) {
      startOnes.current = true;
      dispatch(globalActions.getMainDataRequest());
    }
  }, [isAuthenticated, authenticationDataReceived, isMainDataError, startOnes]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(authenticationActions.logoutUserRequest(Path.Landing));
    }
  }, [isAuthenticated]);

  if (hasError) return <GlobalError />;

  return isFetching ? (
    <Preloader />
  ) : (
    <RainbowLayout>
      <ViewAsUserInfoModal visible={isViewAsUserMode} spinner={isViewAsUserMode && !workspaceId} />
      <Outlet />
      {!outlet && <div> TODO Dashboard</div>}
    </RainbowLayout>
  );
};

import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { MemberType, UpdateBookingPageInput, VideoConferenceType, WorkspaceInput } from '../../API';
import { notificationsActions } from '../notifications';
import {
  CLONE_BOOKING_PAGE_ERROR_TOAST,
  CLONE_BOOKING_PAGE_SUCCESS_TOAST,
  DEFAULT_BOOKING_PAGE_DATA,
  DELETE_BOOKING_PAGES_ERROR_TOAST,
  DELETE_BOOKING_PAGES_SUCCESS_TOAST,
  EMPTY_ACCORDION_INDEXES,
  GET_BOOKING_PAGES_ERROR_TOAST,
  GET_BOOKING_PAGE_ERROR_TOAST,
  SAVE_BOOKING_PAGE_ERROR_TOAST,
  SAVE_BOOKING_PAGE_SUCCESS_TOAST,
} from './constants';
import { bookingPagesActionTypes, bookingPagesActions } from './actions';
import { bookingPageSelectors } from './selectors';
import { deleteBookingPages, getBookingPages, getBookingPage, postBookingPage } from './service';
import { authenticationSelectors } from '../authentication';
import { uploadPublicFile } from '../userSettings/service';
import { GetBookingPagesResponse, PostBookingPageResponse } from './types';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { handleServiceError } from '../utils/reduxUtils';
import { usersSaga } from '../users/sagas';
import { teamsSaga } from '../teams/sagas';
import { bookingTemplatesSagas } from '../bookingTemplates/sagas';
import { workspacesSelectors } from '../workspaces';
import { userSettingsSelectors } from '../userSettings';
import { smartAlertsSagas } from '../smartAlerts/sagas';
import { locationsSagas } from '../locations/sagas';
import { groupBookingPagesSagas } from '../groupBookingPages/sagas';
import { MemberSubType } from '../../types/types';

const selectCreateBookingPageRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  bookingPageSelectors.selectBookingPage,
  userSettingsSelectors.selectNameOrEmail,
  (workspaceId, bookingPage, lastModify) => ({
    ...bookingPage,
    workspaceId,
    lastModify,
  })
);

const selectCloneBookingPageRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  bookingPageSelectors.selectBookingPage,
  bookingPageSelectors.selectCloneName,
  userSettingsSelectors.selectNameOrEmail,
  (workspaceId, bookingPage, cloneName, lastModify) => ({
    ...bookingPage,
    id: '',
    workspaceId,
    adminOnly: false,
    lastModify,
    what: { ...bookingPage.what, customName: cloneName },
  })
);

// copy workspace images if required, remove old and temporary images
function* processFilesOnCreateUpdate() {
  //   // const imageNamesToRemove: string[] = yield select(bookingPageSelectors.selectImageNamesToRemove);
  //   const isWorkspaceLogoToUse: string = yield select(bookingPageSelectors.selectIsWorkspaceLogoToUse);
  //   const isWorkspaceBackgroundToUse: string = yield select(bookingPageSelectors.selectIsWorkspaceBackgroundToUse);
  const logoImage: string = yield select(bookingPageSelectors.selectFinalLogoImage);
  const backgroundImage: string = yield select(bookingPageSelectors.selectFinalBackgroundImage);

  //   // for (const imageName of imageNamesToRemove) {
  //   //   yield fork(removeFile, imageName);
  //   // }

  //   const logoImage: string = isWorkspaceLogoToUse
  //     ? yield call(copyPublicFile, generatePublicFileKey(logo), generatePublicFileKeyCopy(logo))
  //     : logo;

  //   const backgroundImage: string = isWorkspaceBackgroundToUse
  //     ? yield call(copyPublicFile, generatePublicFileKey(background), generatePublicFileKeyCopy(background))
  //     : background;

  yield put(bookingPagesActions.updateStyleStep({ logoImage, backgroundImage }));
}

// remove images from deleted booking pages
// function* processFilesOnDelete() {
//   const imageNamesToRemove: string[] = yield select(bookingPageSelectors.selectSelectedBookingPagesImageNames);
//   for (const imageName of imageNamesToRemove) {
//     yield fork(removeFile, imageName);
//   }
// }

// TODO: find out how resolve typings error when thunk is dispatching from saga
function* getBookingPagesSaga() {
  try {
    const workspace: string = yield select(authenticationSelectors.selectWorkspaceId);
    const response: GetBookingPagesResponse = yield call(getBookingPages, workspace);

    yield put(bookingPagesActions.getBookingPagesSuccess(response.bookingPages));
  } catch (error: unknown) {
    yield put(bookingPagesActions.getBookingPagesFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_PAGES_ERROR_TOAST, false);
  }
}

function* getBookingPagesPageSaga(action: ReturnType<typeof bookingPagesActions.getBookingPagesPageRequest>) {
  try {
    if (action.type === bookingPagesActionTypes.GET_BOOKING_PAGES_PAGE_REQUEST) {
      const workspace: string = yield select(authenticationSelectors.selectWorkspaceId);
      let bookingPages: UpdateBookingPageInput[] = yield select(bookingPageSelectors.selectBookingPages);
      const requestsList = [
        ...(!action.isInitialVisit
          ? [
              call(getBookingPages, workspace),
              call(bookingTemplatesSagas.getBookingTemplates),
              call(groupBookingPagesSagas.getGroupBookingPages),
            ]
          : []),
        call(usersSaga.getUsers),
        call(teamsSaga.getTeams),
      ];
      const [response]: [GetBookingPagesResponse] = yield all(requestsList);

      if (!action.isInitialVisit && response && response.bookingPages) {
        bookingPages = response.bookingPages;
      }

      yield put(bookingPagesActions.getBookingPagesSuccess(bookingPages));
    }
  } catch (error: unknown) {
    yield put(bookingPagesActions.getBookingPagesFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_PAGES_ERROR_TOAST, true);
  }
}

function* getBookingPageSaga(action: ReturnType<typeof bookingPagesActions.getBookingPageRequest>) {
  try {
    if (action.type === bookingPagesActionTypes.GET_BOOKING_PAGE_REQUEST) {
      const id = action.payload.id;
      const isInitial = action.payload.isInitialVisit;

      // prepopulate with existing data
      if (id) {
        const bookingPages: UpdateBookingPageInput[] = yield select(bookingPageSelectors.selectBookingPages);
        const bookingPage = bookingPages.find((page) => page.id === id);
        if (bookingPage) {
          yield put(bookingPagesActions.setBookingPage(bookingPage));
        }
      }

      // set default data for new record
      if (!id) {
        const currentWorkspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);
        const currentWorkspace: WorkspaceInput = yield select(
          workspacesSelectors.selectWorkspaceById(currentWorkspaceId)
        );
        const userId: string = yield select(authenticationSelectors.selectUserId);
        const defaultVideoConference: VideoConferenceType = yield select(
          userSettingsSelectors.selectDefaultVideoIntegration
        );
        const bookingPage: UpdateBookingPageInput = {
          ...DEFAULT_BOOKING_PAGE_DATA,
          workspaceId: currentWorkspaceId,
          labels: currentWorkspace.labels,
          style: {
            ...currentWorkspace.style,
            logoImage: null,
            backgroundImage: null,
          },
          who: {
            ...DEFAULT_BOOKING_PAGE_DATA.who,
            hostMembers: [
              {
                id: userId,
                type: MemberType.SUMO1,
                subType: MemberSubType.USER,
              },
            ],
          },
          where: {
            ...DEFAULT_BOOKING_PAGE_DATA.where,
            videoConferenceType: defaultVideoConference,
          },
        };
        yield put(bookingPagesActions.getBookingPageSuccess(bookingPage));
      }

      const requestsList = [
        ...(id ? [call(getBookingPage, id)] : []),
        ...(!isInitial ? [call(bookingTemplatesSagas.getBookingTemplates)] : []),
        call(usersSaga.getUsers),
        call(teamsSaga.getTeams),
        call(smartAlertsSagas.getSmartTypes),
        call(locationsSagas.getLocations),
      ];
      const [response]: [GetBookingPagesResponse] = yield all(requestsList);

      if (id && response?.bookingPages?.length) {
        yield put(bookingPagesActions.getBookingPageSuccess(response.bookingPages[0]));
        if (!response.bookingPages[0].who?.hostMembers?.length) {
          // if no-host - open who section
          yield put(bookingPagesActions.updateAccordionIndexes({ who: true }));
        }
      } else if (id) {
        throw new Error('BookingPage not found');
      }
    }
  } catch (error: unknown) {
    yield put(bookingPagesActions.getBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_PAGE_ERROR_TOAST);
    yield call(navigationService.navigateTo, Path.BookingPages);
  }
}

function* createBookingPageSaga(action: ReturnType<typeof bookingPagesActions.createBookingPageRequest>) {
  try {
    if (action.type === bookingPagesActionTypes.CREATE_BOOKING_PAGE_REQUEST) {
      const isRedirect = action.payload;
      yield call(processFilesOnCreateUpdate);

      const createBookingPage: UpdateBookingPageInput = yield select(selectCreateBookingPageRequest);
      const response: PostBookingPageResponse = yield call(postBookingPage, createBookingPage);

      if (isRedirect) {
        yield put(bookingPagesActions.clearBookingPage());
        yield call(navigationService.navigateTo, Path.BookingPages);
      } else {
        yield put(
          bookingPagesActions.updateRecord({
            id: response.bookingPage.id,
            displayId: response.bookingPage.displayId,
            shortLink: response.bookingPage.shortLink,
          })
        );
        yield put(bookingPagesActions.updateAccordionIndexes(EMPTY_ACCORDION_INDEXES));
      }

      yield put(bookingPagesActions.createBookingPagesuccess());
      yield put(notificationsActions.showToast(SAVE_BOOKING_PAGE_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(bookingPagesActions.createBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_PAGE_ERROR_TOAST);
  }
}

function* cloneBookingPageSaga() {
  try {
    yield call(processFilesOnCreateUpdate);

    const cloneBookingPage: UpdateBookingPageInput = yield select(selectCloneBookingPageRequest);
    yield call(postBookingPage, cloneBookingPage);

    yield call(navigationService.navigateTo, Path.BookingPages);

    yield put(bookingPagesActions.cloneBookingPagesuccess());
    yield put(notificationsActions.showToast(CLONE_BOOKING_PAGE_SUCCESS_TOAST));
    yield put(bookingPagesActions.getBookingPagesRequest());
  } catch (error: unknown) {
    yield put(bookingPagesActions.cloneBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, CLONE_BOOKING_PAGE_ERROR_TOAST);
  }
}

function* updateBookingPageSaga(action: ReturnType<typeof bookingPagesActions.saveBookingPageRequest>) {
  try {
    if (action.type === bookingPagesActionTypes.SAVE_BOOKING_PAGE_REQUEST) {
      const isRedirect = action.payload;
      yield call(processFilesOnCreateUpdate);

      const bookingPage: UpdateBookingPageInput = yield select(bookingPageSelectors.selectBookingPage);
      const lastModify: string = yield select(userSettingsSelectors.selectNameOrEmail);
      yield call(postBookingPage, { ...bookingPage, lastModify });

      if (isRedirect) {
        yield put(bookingPagesActions.clearBookingPage());
        yield call(navigationService.navigateTo, Path.BookingPages);
      } else {
        yield put(bookingPagesActions.updateAccordionIndexes(EMPTY_ACCORDION_INDEXES));
      }

      yield put(bookingPagesActions.saveBookingPagesuccess());
      yield put(notificationsActions.showToast(SAVE_BOOKING_PAGE_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(bookingPagesActions.saveBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_PAGE_ERROR_TOAST);
  }
}

function* activateBookingPageSaga(action: ReturnType<typeof bookingPagesActions.activateBookingPageRequest>) {
  try {
    if (action.type === bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_REQUEST) {
      const bookingPage = action.payload;
      const lastModify: string = yield select(userSettingsSelectors.selectNameOrEmail);
      yield call(postBookingPage, { ...bookingPage, lastModify });
      yield put(bookingPagesActions.activateBookingPagesuccess());
      yield put(bookingPagesActions.setBookingPage(bookingPage));
      yield put(notificationsActions.showToast(SAVE_BOOKING_PAGE_SUCCESS_TOAST));
      yield put(bookingPagesActions.getBookingPagesPageRequest(false));
    }
  } catch (error: unknown) {
    yield put(bookingPagesActions.activateBookingPageFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_PAGE_ERROR_TOAST);
  }
}

function* deleteBookingPagesSaga() {
  try {
    const ids: string[] = yield select(bookingPageSelectors.selectSelectedBookingPages);
    const workspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);

    yield call(deleteBookingPages, ids, workspaceId);

    // yield call(processFilesOnDelete);

    yield call(navigationService.navigateTo, Path.BookingPages);

    yield put(bookingPagesActions.deleteBookingPagesSuccess());
    yield put(notificationsActions.showToast(DELETE_BOOKING_PAGES_SUCCESS_TOAST));
    yield put(bookingPagesActions.unselectAllBookingPage());
    yield put(bookingPagesActions.getBookingPagesRequest());
  } catch (error: unknown) {
    yield put(bookingPagesActions.deleteBookingPagesFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_BOOKING_PAGES_ERROR_TOAST);
  }
}

function* uploadAvatarFileSaga(action: ReturnType<typeof bookingPagesActions.uploadAvatarFileRequest>) {
  try {
    if (action.type === bookingPagesActionTypes.UPLOAD_AVATAR_FILE_REQUEST) {
      const path: string = yield select(authenticationSelectors.selectTenantFileFolderPath);
      const imageLink: string = yield call(uploadPublicFile, action.payload.file, path);
      yield put(bookingPagesActions.uploadAvatarFileSuccess({ imageType: action.payload.imageType, imageLink }));
    }
  } catch (error: unknown) {
    yield put(bookingPagesActions.uploadAvatarFileFail(error));
  }
}

function* uploadBackgroundFileSaga(action: ReturnType<typeof bookingPagesActions.uploadBackgroundFileRequest>) {
  try {
    if (action.type === bookingPagesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST) {
      const path: string = yield select(authenticationSelectors.selectTenantFileFolderPath);
      const imageLink: string = yield call(uploadPublicFile, action.payload.file, path);
      yield put(bookingPagesActions.uploadBackgroundFileSuccess({ imageType: action.payload.imageType, imageLink }));
    }
  } catch (error: unknown) {
    yield put(bookingPagesActions.uploadBackgroundFileFail(error));
  }
}

export function* watchBookingPagesSaga() {
  yield takeLatest(bookingPagesActionTypes.GET_BOOKING_PAGES_REQUEST, getBookingPagesSaga);
  yield takeLatest(bookingPagesActionTypes.GET_BOOKING_PAGES_PAGE_REQUEST, getBookingPagesPageSaga);
  yield takeLatest(bookingPagesActionTypes.GET_BOOKING_PAGE_REQUEST, getBookingPageSaga);
  yield takeLatest(bookingPagesActionTypes.CREATE_BOOKING_PAGE_REQUEST, createBookingPageSaga);
  yield takeLatest(bookingPagesActionTypes.CLONE_BOOKING_PAGE_REQUEST, cloneBookingPageSaga);
  yield takeLatest(bookingPagesActionTypes.SAVE_BOOKING_PAGE_REQUEST, updateBookingPageSaga);
  yield takeLatest(bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_REQUEST, activateBookingPageSaga);
  yield takeLatest(bookingPagesActionTypes.DELETE_BOOKING_PAGES_REQUEST, deleteBookingPagesSaga);
  yield takeLatest(bookingPagesActionTypes.UPLOAD_AVATAR_FILE_REQUEST, uploadAvatarFileSaga);
  yield takeLatest(bookingPagesActionTypes.UPLOAD_BACKGROUND_FILE_REQUEST, uploadBackgroundFileSaga);
}

export const bookingPagesSagas = {
  getBookingPages: getBookingPagesSaga,
};

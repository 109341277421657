import { call, put, select, takeLatest } from 'redux-saga/effects';
import { authenticationSelectors } from '../authentication';
import { BookingPageLabels, BookingPageStyle, Journey, WorkspaceInput } from '../../API';
import {
  deleteJourneys,
  getJourneyById,
  getJourneyEventsByJourneyId,
  getJourneysByWorkspaceId,
  upsertJourney,
} from './service';
import { JourneysActionTypes, journeysActions } from './actions';
import { GetJourneyByIdResponse, GetJourneyEventsByJourneyIdResponse, GetJourneysByWorkspaceIdResponse } from './types';
import { createSelector } from 'reselect';
import { journeysSelectors } from './selectors';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { cloneJourneysModalActions, deactivateJourneysModalActions } from './modal';
import { handleServiceError } from '../utils/reduxUtils';
import {
  ACTIVATE_JOURNEY_SUCCESS_TOAST,
  CLONE_JOURNEY_ERROR_TOAST,
  CLONE_JOURNEY_SUCCESS_TOAST,
  CREATE_JOURNEY_SUCCESS_TOAST,
  DEACTIVATE_JOURNEY_ERROR_TOAST,
  DEACTIVATE_JOURNEY_SUCCESS_TOAST,
  DEFAULT_JOURNEY,
  DELETE_JOURNEYS_ERROR_TOAST,
  DELETE_JOURNEYS_SUCCESS_TOAST,
  GET_JOURNEY_ERROR_TOAST,
  GET_JOURNEY_EVENTS_ERROR_TOAST,
  GET_JOURNEYS_ERROR_TOAST,
  SAVE_JOURNEY_ERROR_TOAST,
  UPDATE_JOURNEY_SUCCESS_TOAST,
} from './constants';
import { notificationsActions } from '../notifications';
import { getAllCards, getAllFields } from './utils';
import { workspacesSelectors } from '../workspaces';

const selectCloneJourneyRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  authenticationSelectors.selectUserId,
  journeysSelectors.selectJourney,
  journeysSelectors.selectCloneName,
  (workspaceId, userId, journey, cloneName) =>
    ({
      ...journey,
      id: '',
      workspaceId,
      name: cloneName,
      createdBy: userId,
      lastEventDate: null,
      eventsCount: 0,
    } as Journey)
);

const selectUpsertJourneyRequest = createSelector(
  journeysSelectors.selectJourney,
  authenticationSelectors.selectWorkspaceId,
  authenticationSelectors.selectUserId,
  (journey, workspaceId, userId) =>
    ({
      ...journey,
      workspaceId: journey.workspaceId || workspaceId,
      createdBy: journey.createdBy || userId,
    } as Journey)
);

function* getJourneysSaga() {
  try {
    const workspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);
    const response: GetJourneysByWorkspaceIdResponse = yield call(getJourneysByWorkspaceId, workspaceId);

    yield put(journeysActions.getJourneysSuccess(response));
  } catch (error: unknown) {
    yield put(journeysActions.getJourneysFail(error?.toString()));
    yield call(handleServiceError, error, GET_JOURNEYS_ERROR_TOAST, true);
  }
}

function* getJourneySaga(action: ReturnType<typeof journeysActions.getJourneyRequest>) {
  try {
    if (action.type === JourneysActionTypes.GET_JOURNEY_REQUEST) {
      let response = {} as GetJourneyByIdResponse;

      if (action.payload === 'new') {
        const currentWorkspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);
        const currentWorkspace: WorkspaceInput = yield select(
          workspacesSelectors.selectWorkspaceById(currentWorkspaceId)
        );
        response.journey = {
          ...DEFAULT_JOURNEY,
          labels: currentWorkspace.labels as BookingPageLabels,
          style: {
            ...currentWorkspace.style,
            logoImage: null,
            backgroundImage: null,
          } as BookingPageStyle,
        };
      } else {
        response = yield call(getJourneyById, action.payload);
        const fields = getAllFields(response.journey.root);
        const cards = getAllCards(response.journey.root);
        yield put(journeysActions.setFieldsAndCards({ fields, cards }));
      }
      yield put(journeysActions.getJourneySuccess(response));
    }
  } catch (error: unknown) {
    yield put(journeysActions.getJourneyFail(error?.toString()));
    yield call(handleServiceError, error, GET_JOURNEY_ERROR_TOAST, true);
  }
}

function* cloneJourneySaga() {
  try {
    const journey: Journey = yield select(selectCloneJourneyRequest);
    yield call(upsertJourney, journey);

    yield put(journeysActions.cloneJourneySuccess());
    yield put(cloneJourneysModalActions.closeModal());

    yield put(notificationsActions.showToast(CLONE_JOURNEY_SUCCESS_TOAST));
    yield put(journeysActions.getJourneysRequest());
  } catch (error: unknown) {
    yield put(journeysActions.cloneJourneyFail(error?.toString()));
    yield call(handleServiceError, error, CLONE_JOURNEY_ERROR_TOAST);
  }
}

function* deleteJourneysSaga(action: ReturnType<typeof journeysActions.deleteJourneysRequest>) {
  try {
    if (action.type === JourneysActionTypes.DELETE_JOURNEYS_REQUEST) {
      yield call(deleteJourneys, action.payload);

      yield put(journeysActions.deleteJourneysSuccess());
      yield put(notificationsActions.showToast(DELETE_JOURNEYS_SUCCESS_TOAST));
      yield put(journeysActions.getJourneysRequest());
    }
  } catch (error: unknown) {
    yield put(journeysActions.deleteJourneysFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_JOURNEYS_ERROR_TOAST);
  }
}

function* deactivateJourneySaga() {
  try {
    const journey: Journey = yield select(journeysSelectors.selectJourney);
    yield call(upsertJourney, { ...journey, active: !journey.active });

    yield put(journeysActions.deactivateJourneySuccess());
    if (journey.active) {
      yield put(deactivateJourneysModalActions.closeModal());
      yield put(notificationsActions.showToast(DEACTIVATE_JOURNEY_SUCCESS_TOAST));
    } else {
      yield put(notificationsActions.showToast(ACTIVATE_JOURNEY_SUCCESS_TOAST));
    }
    yield put(journeysActions.getJourneysRequest());
  } catch (error: unknown) {
    yield put(journeysActions.deactivateJourneyFail(error?.toString()));
    yield call(handleServiceError, error, DEACTIVATE_JOURNEY_ERROR_TOAST);
  }
}

function* upsertJourneySaga() {
  try {
    const journey: Journey = yield select(selectUpsertJourneyRequest);

    // const response: GetJourneyByIdResponse =
    yield call(upsertJourney, journey);

    yield put(journeysActions.upsertJourneySuccess());
    yield put(
      notificationsActions.showToast(!journey.id ? CREATE_JOURNEY_SUCCESS_TOAST : UPDATE_JOURNEY_SUCCESS_TOAST)
    );
    yield call(navigationService.navigateTo, Path.Journeys);
  } catch (error: unknown) {
    yield put(journeysActions.upsertJourneyFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_JOURNEY_ERROR_TOAST);
  }
}

function* getJourneyEventsSaga() {
  try {
    const journeyId: string = yield select(journeysSelectors.selectJourneyId);
    const response: GetJourneyEventsByJourneyIdResponse = yield call(getJourneyEventsByJourneyId, journeyId);
    response.journeyEvents.sort((a, b) => (a.createdAt <= b.createdAt ? 1 : -1));

    yield put(journeysActions.getJourneyEventsSuccess(response));
  } catch (error: unknown) {
    yield put(journeysActions.getJourneyEventsFail(error?.toString()));
    yield call(handleServiceError, error, GET_JOURNEY_EVENTS_ERROR_TOAST, true);
  }
}
export function* watchJourneysSaga() {
  yield takeLatest(JourneysActionTypes.GET_JOURNEYS_REQUEST, getJourneysSaga);
  yield takeLatest(JourneysActionTypes.CLONE_JOURNEY_REQUEST, cloneJourneySaga);
  yield takeLatest(JourneysActionTypes.DELETE_JOURNEYS_REQUEST, deleteJourneysSaga);
  yield takeLatest(JourneysActionTypes.DEACTIVATE_JOURNEY_REQUEST, deactivateJourneySaga);
  yield takeLatest(JourneysActionTypes.GET_JOURNEY_REQUEST, getJourneySaga);
  yield takeLatest(JourneysActionTypes.UPSERT_JOURNEY_REQUEST, upsertJourneySaga);
  yield takeLatest(JourneysActionTypes.GET_JOURNEY_EVENTS_REQUEST, getJourneyEventsSaga);
}

export const journeysSagas = {
  getJourneys: getJourneysSaga,
};

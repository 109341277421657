import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  InPersonType,
  LocationType,
  TimeFormat,
  TimeZoneType,
  PhoneCallType,
  ConfirmationsPageType,
  CustomFieldType,
  CustomFieldInput,
  JourneyStepType,
  JourneyPageType,
  JourneyFormFieldType,
} from '../../API';
import { CURRENT_TIME_ZONE, TIME_ZONES_BY_NAME } from '../../types/constants';
import { State } from '../rootStore';
import {
  PREVIEW_AVAILABLE_TIMES,
  IN_PERSON_LABEL,
  PHONE_LABEL,
  VIDEO_CONFERENCE_LABEL,
  MAX_LOCATION_DISTANCE,
} from './constants';
import { EventSteps } from './types';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { userSettingsSelectors } from '../userSettings';
import { converDateStringToCurrentTimezoneDate } from '../../services/DateService';
import {
  findJourneyCurrentStepForView,
  findJourneyStepById,
  findNextStep,
  findPreviousStep,
  formatPhoneNumber,
  validateCustomField,
  validateFormField,
} from './utils';
import { globalSelectors } from '../global';
import { Coordinates, getDistanceBetweenPointsMiKm, LocationWithDistance } from '../locations';
import { validateEmail } from '../../services/EmailService';

dayjs.extend(utc);
dayjs.extend(timezone);

const eventState = (state: State) => state.event;

const selectEvent = createSelector(eventState, (state) => state.event);
const selectAgenda = createSelector(eventState, (state) => state.agenda);
const selectSteps = createSelector(eventState, (state) => state.steps);
const selectJourneyId = createSelector(eventState, (state) => state.journeyId);
const selectBookingPageId = createSelector(eventState, (state) => state.bookingPageId || '');
const selectBookedMeetingId = createSelector(eventState, (state) => state.eventId || '');
const selectViewDate = createSelector(eventState, (state) => new Date(state.viewDate));
const selectIsRescheduleMode = createSelector(eventState, (state) => state.isRescheduleMode);
const selectIsPreviewMode = createSelector(eventState, (state) => state.isPreviewMode);
const selectIsReadOnly = createSelector(eventState, (state) => state.isReadOnly);
const selectIsHostReschedule = createSelector(eventState, (state) => state.isHostReschedule);
const selectIsRescheduled = createSelector(eventState, (state) => state.isRescheduled);
const selectIsFetching = createSelector(eventState, (state) => state.isFetching);
const selectIsUpsertEventFetching = createSelector(eventState, (state) => state.isUpsertEventFetching);
const selectError = createSelector(eventState, (state) => state.error);
const selectAgendaLoaded = createSelector(eventState, (state) => state.agendaLoaded);
const selectViewSettings = createSelector(eventState, (state) => state.viewSettings);
const selectGroupBookingPageId = createSelector(eventState, (state) => state.groupBookingPageId);
const selectChosenHostMemberId = createSelector(eventState, (state) => state.chosenHostMemberId);
const selectIsLocationOpened = createSelector(eventState, (state) => state.isLocationOpened);

const selectStep = createSelector(selectSteps, (state) => state.find((step) => step.active)?.step || EventSteps.WHEN);

const selectIsEditEvent = createSelector(selectEvent, (state) => !!state.editKey);

const selectGoogleAnalytics = createSelector(selectAgenda, (state) => state?.googleAnalytics);
const selectAgendaSpots = createSelector(selectAgenda, (state) => state.spots || []);
const selectHasSmartAlertSms = createSelector(selectAgenda, (state) => state.hasSmartAlertSms);
const selectPotentialHosts = createSelector(selectAgenda, (state) => state.potentialHosts);
const selectInitialHost = createSelector(selectAgenda, (state) => state.initialHost);
const selectFinalHost = createSelector(selectAgenda, (state) => state.host);
const selectInitialCohosts = createSelector(selectAgenda, (state) => state.initialCohosts); // TODO: rename please to gusets as in schema.graphql
const selectFinalCohosts = createSelector(selectAgenda, (state) => state.cohosts); // TODO: rename please to gusets as in schema.graphql
const selectHost = createSelector(
  selectStep,
  selectInitialHost,
  selectFinalHost,
  (step, initialHost, host) => ([EventSteps.BOOKED, EventSteps.CANCEL].includes(step) && host) || initialHost
); // TODO: rename please to gusets as in schema.graphql
const selectCohosts = createSelector(
  selectStep,
  selectInitialCohosts,
  selectFinalCohosts,
  (step, initialCohosts, cohosts) =>
    ([EventSteps.BOOKED, EventSteps.CANCEL].includes(step) && cohosts) || initialCohosts
); // TODO: rename please to gusets as in schema.graphql
const selectHostName = createSelector(selectHost, (state) => state?.name);
const selectHostCountryCode = createSelector(selectHost, (state) => state?.countryCode);
const selectHostPhone = createSelector(selectHost, (state) => state?.phone);
const selectHostEmail = createSelector(selectHost, (state) => state?.email);
const selectHostDisplayName = createSelector(selectHostName, selectHostEmail, (name, email) => name || email);
const selectHostIsTeam = createSelector(selectHost, (state) => !!state?.isTeam);
const selectAllHosts = createSelector(
  selectHost,
  selectCohosts,
  selectChosenHostMemberId,
  selectPotentialHosts,
  (host, cohosts, chosenHostMemberId, potentialHosts) => [
    chosenHostMemberId ? potentialHosts?.find((host) => host.id === chosenHostMemberId) : host,
    ...(cohosts || []),
  ]
);
// TODO: rename please to gusets as in schema.graphql
const selectHostFullPhone = createSelector(
  selectHostCountryCode,
  selectHostPhone,
  (countryCode, phone) => phone && formatPhoneNumber(countryCode || '', phone || '')
);

const selectEventId = createSelector(selectEvent, (state) => state.eventId);
const selectIsExisting = createSelector(selectEventId, (state) => Boolean(state));
const selectEventEditKey = createSelector(selectEvent, (state) => state.editKey || null);
const selectEventDateString = createSelector(selectEvent, (state) => state.eventDate);
const selectEventDate = createSelector(selectEventDateString, (eventDate) =>
  eventDate ? converDateStringToCurrentTimezoneDate(eventDate) : new Date()
);
const selectEventTimezone = createSelector(selectEvent, (state) => state.timeZone);
const selectEventCancelPolicy = createSelector(selectEvent, (state) => state.cancelationPolicy);
const selectEventCanceled = createSelector(selectEvent, (state) => state.canceled);
const selectEventIsCanceled = createSelector(selectEventCanceled, (canceled) => canceled?.isCanceled);
const selectEventCanceledType = createSelector(selectEventCanceled, (canceled) => canceled?.type);
const selectEventCanceledNote = createSelector(selectEventCanceled, (canceled) => canceled?.note);
const selectStartTime = createSelector(selectEvent, (state) => (state.startTime ? new Date(state.startTime) : null));
const selectEventLocation = createSelector(selectEvent, (event) => event.location);
const selectEventLocationType = createSelector(selectEventLocation, (location) => location?.type);
const selectLocationSettings = createSelector(selectEventLocation, (location) => location?.settings);
const selectLocationAddress = createSelector(selectEventLocation, (location) => location?.address);
const selectIsSMSAllowed = createSelector(selectEvent, (event) => Boolean(event.isSMSAllowed));
const selectAdditionalGuestEmails = createSelector(selectEvent, (event) => event.guestEmails || []);
const selectNote = createSelector(selectEvent, (event) => event.note || '');
const selectInputFields = createSelector(selectEvent, (event) => event.inputFields || []);
const selectAttendeeName = createSelector(
  selectInputFields,
  (inputFields) => inputFields?.find((field) => field && field.fieldType === CustomFieldType.NAME)?.value
);
const selectAttendeeEmail = createSelector(
  selectInputFields,
  (inputFields) => inputFields?.find((field) => field && field.fieldType === CustomFieldType.EMAIL)?.value
);
const selectAttendeePhone = createSelector(
  selectInputFields,
  (inputFields) => inputFields?.find((field) => field && field.fieldType === CustomFieldType.PHONE)?.value
);
const selectAttendeeLocation = createSelector(
  selectInputFields,
  (inputFields) => inputFields?.find((field) => field && field.fieldType === CustomFieldType.LOCATION)?.value
);
const selectAttendeePhoneFormatted = createSelector(selectAttendeePhone, (phone) => `+${(phone || '').slice(3)}`);

const selectIsPastEvent = createSelector(
  selectStartTime,
  userSettingsSelectors.selectUTC,
  (startTime, utcMillis) => startTime && startTime <= new Date(utcMillis)
);

const selectAdditionalGuestEmailsValidation = createSelector(selectAdditionalGuestEmails, (additionalguestEmails) =>
  additionalguestEmails.map((email) => (email ? validateEmail(email) : false))
);

const selectEventStyle = createSelector(selectEvent, (state) => state?.style);
const selectEventLabels = createSelector(selectEvent, (state) => state?.labels);

const selectJourney = createSelector(selectAgenda, (agenda) => agenda.journey);
const selectJourneyStyle = createSelector(selectJourney, (state) => state?.style);
const selectJourneyLabels = createSelector(selectJourney, (state) => state?.labels);
const selectJourneySavedSteps = createSelector(eventState, (state) => state.journeySteps);
const selectSavedLastStep = createSelector(selectJourneySavedSteps, (steps) => steps[steps.length - 1] || null);
const selectSavedCurrentStep = createSelector(selectJourneySavedSteps, (steps) => findJourneyCurrentStepForView(steps));
const selectJourneyCurrentStep = createSelector(selectJourney, selectSavedCurrentStep, (journey, savedCurrentStep) => {
  return findJourneyStepById(journey?.root, savedCurrentStep?.id);
});

const selectJourneySelectedCard = createSelector(selectSavedCurrentStep, (savedCurrentStep) =>
  savedCurrentStep?.page?.cards?.length ? savedCurrentStep?.page?.cards[0] : null
);
const selectIsJourneyBackAvailable = createSelector(
  selectJourney,
  selectSavedCurrentStep,
  (journey, savedCurrentStep) => journey?.root?.id !== savedCurrentStep?.id
);
const selectIsJourneyNextAvailable = createSelector(
  selectSavedCurrentStep,
  (savedCurrentStep) =>
    (savedCurrentStep?.page?.type === JourneyPageType.CARD && Boolean(savedCurrentStep?.page?.cards?.length)) ||
    (savedCurrentStep?.page?.type === JourneyPageType.FORM &&
      savedCurrentStep?.page?.formFields?.every((field) => validateFormField(field)))
);

const selectGroupBookingPage = createSelector(selectAgenda, (agenda) => agenda.groupBookingPage);
const selectGroupBookingPages = createSelector(selectAgenda, (agenda) => agenda.groupBookingPages);
const selectGroupBookingPageStyle = createSelector(selectGroupBookingPage, (state) => state?.style);
const selectGroupBookingPageLabels = createSelector(selectGroupBookingPage, (state) => state?.labels);

const selectBookingPage = createSelector(selectAgenda, (agenda) => agenda.bookingPage);
const selectBookingPageStyle = createSelector(selectBookingPage, (state) => state?.style);
const selectBookingPageLabels = createSelector(selectBookingPage, (state) => state?.labels);

// event | journey | group | booking page style
const selectStyle = createSelector(
  selectEventStyle,
  selectJourneyStyle,
  selectGroupBookingPageStyle,
  selectBookingPageStyle,
  (eventStyle, journeyStyle, groupBookingPageStyle, bookingPageStyle) =>
    eventStyle || journeyStyle || groupBookingPageStyle || bookingPageStyle
);
const selectLogo = createSelector(selectStyle, (style) => style?.logoImage);
const selectBackground = createSelector(selectStyle, (style) => style?.backgroundImage);
const selectBackgroundType = createSelector(selectStyle, (style) => style?.backgroundType);
const selectBackgroundColor = createSelector(selectStyle, (style) => style?.backgroundColor);
const selectPrimaryColor = createSelector(selectStyle, (style) => style?.primaryColor);
const selectCss = createSelector(selectStyle, (style) => style?.css);
const selectFooterHtml = createSelector(selectStyle, (style) => style?.footerHtml);

// event | journey | group | booking page labels
const selectLabels = createSelector(
  selectEventLabels,
  selectJourneyLabels,
  selectGroupBookingPageLabels,
  selectBookingPageLabels,
  (eventLabels, groupBookingPageLabels, journeyLabels, bookingPageLabels) =>
    eventLabels || journeyLabels || groupBookingPageLabels || bookingPageLabels
);
const selectEventNameLabel = createSelector(selectLabels, (how) => how?.eventNameLabel || '');
const selectBookButtonLabel = createSelector(selectLabels, (how) => how?.bookButtonLabel || '');
const selectBookAnotherButtonLabel = createSelector(selectLabels, (how) => how?.bookAnotherButtonLabel || '');
const selectBookTitle = createSelector(selectLabels, (how) => how?.bookTitle || '');
const selectBookDescription = createSelector(selectLabels, (how) => how?.bookDescription || '');
const selectRescheduleTitle = createSelector(selectLabels, (how) => how?.rescheduleTitle || '');
const selectRescheduleDescription = createSelector(selectLabels, (how) => how?.rescheduleDescription || '');
const selectCancelTitle = createSelector(selectLabels, (how) => how?.cancelTitle || '');
const selectCancelDescription = createSelector(selectLabels, (how) => how?.cancelDescription || '');
const selectCancelButtonLabel = createSelector(selectLabels, (how) => how?.cancelButtonLabel || '');
const selectConfirmCancelButtonLabel = createSelector(selectLabels, (how) => how?.confirmCancelButtonLabel || '');
const selectCancelLabel = createSelector(selectLabels, (how) => how?.cancelLabel || '');
const selectDetailsLabel = createSelector(selectLabels, (how) => how?.detailsLabel || '');
const selectGuestsLabel = createSelector(selectLabels, (how) => how?.guestsLabel || '');
const selectUpdateButtonLabel = createSelector(selectLabels, (how) => how?.updateButtonLabel || '');

// booking page
const selectDateRange = createSelector(selectBookingPage, (bookingPage) => bookingPage?.when?.dateRange);
const selectDuration = createSelector(selectBookingPage, (bookingPage) => bookingPage?.when?.duration);
const selectIsSMSEnabled = createSelector(selectBookingPage, selectHasSmartAlertSms, (bookingPage, hasSmartAlertSms) =>
  Boolean(bookingPage?.notifications?.smsReminder?.enabled || hasSmartAlertSms)
);

const selectIsRedirect = createSelector(
  selectIsPreviewMode,
  selectBookingPage,
  (isPreviewMode, bookingTemplate) =>
    !isPreviewMode && bookingTemplate?.confirmationsPage?.type === ConfirmationsPageType.REDIRECT
);
const selectExternalLink = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.confirmationsPage?.externalLink
);
const selectCustomLinks = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.confirmationsPage?.customLinks || []
);
const selectBookAnotherEnabled = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.confirmationsPage?.anotherEventEnabled
);
const selectRescheduleEnabled = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.confirmationsPage?.rescheduleEnabled
);
const selectCancelEnabled = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.confirmationsPage?.cancelEnabled
);

// const selectAvailableLocationTypes = createSelector(
//   selectBookingPage,
//   selectHostVideoIntegrations,
//   (bookingTemplate, hostVideoIntegrations) =>
//     calculateAvailableLocationTypes(bookingTemplate?.where?.locationTypes, hostVideoIntegrations)
// );
const selectAvailableLocationTypes = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.locationTypes || []
);

const selectDefaultLocationType = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.defaultLocationType
);
const selectLocationTypeOptions = createSelector(selectAvailableLocationTypes, (locationTypes) =>
  locationTypes?.reduce((options, locationType) => {
    switch (locationType) {
      case LocationType.IN_PERSON:
        return [...options, { label: IN_PERSON_LABEL, value: LocationType.IN_PERSON }];
      case LocationType.PHONE_CALL:
        return [...options, { label: PHONE_LABEL, value: LocationType.PHONE_CALL }];
      case LocationType.VIDEO_CONFERENCE:
        return [...options, { label: VIDEO_CONFERENCE_LABEL, value: LocationType.VIDEO_CONFERENCE }];
      default:
        return options;
    }
  }, [] as SelectItemOptionsType)
);
const selectIsLocationTypeEdit = createSelector(
  selectIsExisting,
  selectAvailableLocationTypes,
  selectStep,
  (isExisting, locationTypes, step) =>
    !isExisting && Number(locationTypes?.length) > 1 && (step === EventSteps.WHEN || step === EventSteps.INFO)
);

const selectLocations = createSelector(selectBookingPage, (bookingTemplate) => bookingTemplate?.where?.locations || []);
const selectCustomPhoneNumber = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.customPhone || ''
);
const selectCustomCountryCode = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.customCountryCode || ''
);
const selectCustomPhone = createSelector(selectCustomCountryCode, selectCustomPhoneNumber, (countryCode, phoneNumber) =>
  formatPhoneNumber(countryCode, phoneNumber)
);
const selectCustomAddress = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.customAddress
);
const selectPhoneCallType = createSelector(
  selectBookingPage,
  (bookingTemplate) => bookingTemplate?.where?.phoneCallType
);
const selectInPersonType = createSelector(selectBookingPage, (bookingTemplate) => bookingTemplate?.where?.inPersonType);
const selectLocationOptions = createSelector(
  selectLocations,
  (locations) => locations.map((location) => ({ label: location, value: location })) || ([] as SelectItemOptionsType[])
);
const selectShowLocationInput = createSelector(
  selectIsExisting,
  selectEventLocationType,
  selectInPersonType,
  selectLocations,
  selectStep,
  (isExisting, locationType, inPersonType, locations, step) =>
    !isExisting &&
    locationType === LocationType.IN_PERSON &&
    inPersonType === InPersonType.LOCATION &&
    locations.length &&
    (step === EventSteps.WHEN || step === EventSteps.INFO)
);

const selectShowHostPhone = createSelector(
  selectEventLocationType,
  selectPhoneCallType,
  (locationType, phoneCallType) =>
    locationType === LocationType.PHONE_CALL && phoneCallType === PhoneCallType.HOST_PHONE_NUMBER
);
const selectShowCustomPhone = createSelector(
  selectEventLocationType,
  selectPhoneCallType,
  (locationType, phoneCallType) =>
    locationType === LocationType.PHONE_CALL && phoneCallType === PhoneCallType.CUSTOM_PHONE
);
const selectShowCustomAddress = createSelector(
  selectEventLocationType,
  selectInPersonType,
  (locationType, inPersonType) =>
    locationType === LocationType.IN_PERSON && inPersonType === InPersonType.CUSTOM_ADDRESS
);
const selectShowLocationSettings = createSelector(
  selectIsExisting,
  selectEventLocationType,
  selectPhoneCallType,
  selectInPersonType,
  selectStep,
  (isExisting, locationType, phoneCallType, inPersonType, step) =>
    ((locationType === LocationType.PHONE_CALL && phoneCallType === PhoneCallType.PROMPT_INVITE) ||
      (locationType === LocationType.IN_PERSON &&
        (inPersonType === InPersonType.LOCATION || inPersonType === InPersonType.INVITEE_LOCATION))) &&
    (isExisting || step === EventSteps.BOOKED || step === EventSteps.CANCEL)
);

const selectLocationType = createSelector(
  selectEventLocation,
  selectDefaultLocationType,
  (location, defaultLocationType) => location?.type || defaultLocationType
);
const selectLocationTypeLabel = createSelector(selectLocationType, (locationType) => {
  switch (locationType) {
    case LocationType.IN_PERSON:
      return IN_PERSON_LABEL;
    case LocationType.PHONE_CALL:
      return PHONE_LABEL;
    case LocationType.VIDEO_CONFERENCE:
      return VIDEO_CONFERENCE_LABEL;
    default:
      return IN_PERSON_LABEL;
  }
});

const selectPersonalInputFields = createSelector(selectInputFields, (inputFields) =>
  inputFields.filter((field) => field?.fieldType === CustomFieldType.NAME || field?.fieldType === CustomFieldType.EMAIL)
);

const selectFilteredInputFields = createSelector(
  selectEventLocationType,
  selectPhoneCallType,
  selectInputFields,
  selectIsSMSEnabled,
  selectIsSMSAllowed,
  (locationType, phoneCallType, inputFields, isSMSEnabled, isSMSAllowed) =>
    inputFields.reduce((res, field) => {
      if (
        !field ||
        !field.enabled ||
        field.fieldType === CustomFieldType.NAME ||
        field.fieldType === CustomFieldType.EMAIL
      ) {
        return res;
      }

      if (field.fieldType === CustomFieldType.LOCATION) {
        if (locationType === LocationType.IN_PERSON) {
          res.push(field);
        }
        return res;
      }

      if (field.fieldType === CustomFieldType.PHONE) {
        if (locationType === LocationType.PHONE_CALL) {
          res.push({ ...field, required: phoneCallType === PhoneCallType.PROMPT_INVITE || isSMSAllowed });
        } else if (isSMSEnabled) {
          res.push({ ...field, required: isSMSAllowed });
        }
        return res;
      }

      res.push(field);
      return res;
    }, new Array<CustomFieldInput>())
);

const selectPageName = createSelector(selectBookingPage, (state) => state?.what?.customName);
const selectInviteOthers = createSelector(selectBookingPage, (state) => state?.inviteOthers);
const selectEnterNote = createSelector(selectBookingPage, (state) => state?.enterNote);

const selectBookingPageCalendar = createSelector(selectBookingPage, (state) => state?.calendar);
const selectTimeFormat = createSelector(selectBookingPageCalendar, (state) => state?.timeFormat);
const selectTimeZoneType = createSelector(selectBookingPageCalendar, (state) => state?.timeZoneType);
const selectTimeZones = createSelector(selectBookingPageCalendar, (state) => state?.selectedTimeZone || []);
const selectDefaultTimeZone = createSelector(selectBookingPageCalendar, (state) => state?.defaultTimeZone);

const selectIs12Hour = createSelector(selectTimeFormat, (state) => state === TimeFormat.HOUR_12);
const selectHasAdditionalOptions = createSelector(
  selectInviteOthers,
  selectEnterNote,
  (inviteOthers, enterNote) => Boolean(inviteOthers) || Boolean(enterNote)
);
const selectTimeZoneOptions = createSelector(
  selectTimeZoneType,
  selectTimeZones,
  selectIsHostReschedule,
  (type, timeZones, isHostReschedule) => {
    const timeZoneOptions =
      type === TimeZoneType.DISPLAY_SELECTED
        ? timeZones.map((timeZone) => TIME_ZONES_BY_NAME[timeZone || ''])
        : Object.values(TIME_ZONES_BY_NAME);
    // if it's Reschedule from BookedMeetings and there is no Current Timezone in the list - added
    if (isHostReschedule && !timeZoneOptions.some((timeZone) => timeZone.timeZone === CURRENT_TIME_ZONE)) {
      timeZoneOptions.push(TIME_ZONES_BY_NAME[CURRENT_TIME_ZONE]);
    }
    return timeZoneOptions.sort((a, b) => a.offsetHours - b.offsetHours);
  }
);

const selectTimeZoneOptionsList = createSelector(
  selectTimeZoneOptions,
  userSettingsSelectors.selectUTC,
  selectTimeFormat,
  (timeZones, UTC, timeFormat) =>
    timeZones.map((timeZone) => ({
      ...timeZone,
      time: dayjs(UTC)
        .tz(timeZone.timeZone)
        .format(timeFormat !== TimeFormat.HOUR_12 ? 'HH:mm' : 'hh:mm A'),
    }))
);

const selectTimeZone = createSelector(
  selectEventTimezone,
  selectTimeZoneType,
  selectDefaultTimeZone,
  selectIsHostReschedule,
  (timeZone, type, defaultTimeZone, isHostReschedule) =>
    isHostReschedule
      ? CURRENT_TIME_ZONE
      : timeZone || (type === TimeZoneType.DISPLAY_SELECTED && defaultTimeZone) || CURRENT_TIME_ZONE
);

const selectAvailableTimesByDate = createSelector(selectAgendaSpots, selectTimeZone, (spots, timeZone) =>
  spots.reduce((dates, spot) => {
    const spotInt = parseInt(spot);
    const date = dayjs(spotInt).tz(timeZone).format('YYYY-MM-DD');
    dates[date] = [...(dates[date] || []), spotInt];
    return dates;
  }, {} as { [key: string]: number[] })
);

const selectAvailableTimes = createSelector(
  selectIsPreviewMode,
  selectEventDate,
  selectAvailableTimesByDate,
  selectTimeZone,
  selectIs12Hour,
  (isPreviewMode, eventDate, timesByDate, timeZone, is12Hour) =>
    (isPreviewMode ? PREVIEW_AVAILABLE_TIMES : eventDate && timesByDate[dayjs(eventDate).format('YYYY-MM-DD')])?.map(
      (spot) => {
        const label = dayjs(spot)
          .tz(timeZone)
          .format(is12Hour ? 'hh:mm a' : 'HH:mm');
        return {
          value: spot,
          time: label.split(' ')[0],
          format: label.split(' ')[1],
        };
      }
    ) || []
);

const selectDisabledDates = createSelector(selectAvailableTimesByDate, selectViewDate, (timesByDate, viewDate) => {
  const year = viewDate.getFullYear();
  const month = viewDate.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const disabledDays: Set<string> = new Set();

  for (let i = 1; i <= daysInMonth; i++) {
    const date = dayjs().year(year).month(month).date(i).format('YYYY-MM-DD');
    if (!timesByDate[date]) {
      disabledDays.add(date);
    }
  }

  return Array.from(disabledDays).map((day) => converDateStringToCurrentTimezoneDate(day));
});

const selectEditKey = createSelector(selectViewSettings, (settings) => settings.editKey);
const selectIsHideHeader = createSelector(selectViewSettings, (settings) => settings.hideHeader);
const selectIsHideCookie = createSelector(selectViewSettings, (settings) => settings.hideCookie);
const selectIsHidePageDetails = createSelector(selectViewSettings, (settings) => settings.hidePageDetails);
const selectIsIframe = createSelector(selectViewSettings, (settings) => settings.iframe);

const selectIsEditKeyValid = createSelector(
  selectEventEditKey,
  selectEditKey,
  (eventEditKey, editKey) => eventEditKey === editKey
);

const selectShowFooter = createSelector(selectIsIframe, (isIframe) => !isIframe);

const selectShowError = createSelector(selectError, (error) => Boolean(error));

const selectShowJourneyState = createSelector(
  selectStep,
  selectShowError,
  selectIsLocationOpened,
  (step, error, isLocationOpened) => !error && !isLocationOpened && step === EventSteps.JOURNEY
);
const selectShowGroupState = createSelector(
  selectStep,
  selectShowError,
  selectIsLocationOpened,
  (step, error, isLocationOpened) => !error && !isLocationOpened && step === EventSteps.GROUP
);
const selectShowPotentialHosts = createSelector(
  selectStep,
  selectShowError,
  selectIsLocationOpened,
  (step, error, isLocationOpened) => !error && !isLocationOpened && step === EventSteps.WHO
);
const selectShowLocations = createSelector(
  selectShowError,
  selectIsLocationOpened,
  (error, isLocationOpened) => !error && isLocationOpened
);
const selectShowDateTime = createSelector(
  selectStep,
  selectShowError,
  selectIsLocationOpened,
  (step, error, isLocationOpened) => !error && !isLocationOpened && step === EventSteps.WHEN
);
const selectShowYourInfo = createSelector(
  selectStep,
  selectShowError,
  selectIsLocationOpened,
  (step, error, isLocationOpened) => !error && !isLocationOpened && step === EventSteps.INFO
);
const selectShowBookedState = createSelector(
  selectStep,
  selectShowError,
  selectIsLocationOpened,
  (step, error, isLocationOpened) => !error && !isLocationOpened && step === EventSteps.BOOKED
);
const selectShowCancel = createSelector(
  selectStep,
  selectShowError,
  selectIsLocationOpened,
  (step, error, isLocationOpened) => !error && !isLocationOpened && step === EventSteps.CANCEL
);
const selectShowBookingState = createSelector(
  selectShowDateTime,
  selectShowYourInfo,
  selectShowCancel,
  (showWhen, showInfo, showCancel) => showWhen || showInfo || showCancel
);

const selectIsLocationInPerson = createSelector(
  selectLocationType,
  selectInPersonType,
  (locationType, inPersonType) => locationType === LocationType.IN_PERSON && inPersonType === InPersonType.LOCATION
);

const selectShowJourneyPageStep = createSelector(
  selectShowJourneyState,
  selectJourneyCurrentStep,
  (showJourney, currentStep) => showJourney && currentStep?.type === JourneyStepType.PAGE
);
const selectShowJourneyDestinationStep = createSelector(
  selectShowJourneyState,
  selectJourneyCurrentStep,
  (showJourney, currentStep) => showJourney && currentStep?.type === JourneyStepType.DESTINATION
);

// const selectShowBookedInfo = createSelector(
//   selectStep,
//   selectShowError,
//   selectIsRescheduleMode,
//   (step, error, isRescheduleMode) => !isRescheduleMode && !error && step === EventSteps.BOOKED
// );
// const selectShowRescheduledMessage = createSelector(
//   selectIsPreviewMode,
//   selectIsRescheduled,
//   (isPreviewMode, isRescheduled) => isPreviewMode || isRescheduled
// );
// const selectShowCanceledMessage = createSelector(
//   selectIsPreviewMode,
//   selectEventIsCanceled,
//   (isPreviewMode, isCanceled) => isPreviewMode || isCanceled
// );
// const selectShowBookedMessage = createSelector(
//   selectIsPreviewMode,
//   selectShowRescheduledMessage,
//   selectShowCanceledMessage,
//   (isPreviewMode, isRescheduled, isCanceled) => isPreviewMode || (!isRescheduled && !isCanceled)
// );
const selectPageTitle = createSelector(
  selectStep,
  selectEventNameLabel,
  selectBookTitle,
  selectRescheduleTitle,
  selectCancelTitle,
  selectCancelLabel,
  selectIsRescheduled,
  selectEventIsCanceled,
  selectJourneyCurrentStep,
  (
    step,
    eventNameLabel,
    bookTitle,
    rescheduleTitle,
    cancelTitle,
    cancelLabel,
    isRescheduled,
    isCanceled,
    journeyStep
  ) =>
    step === EventSteps.BOOKED
      ? (isRescheduled && rescheduleTitle) || (isCanceled && cancelTitle) || bookTitle
      : step === EventSteps.CANCEL
      ? cancelLabel
      : step === EventSteps.JOURNEY
      ? journeyStep?.page?.cardTitle ||
        journeyStep?.page?.formFields?.find((field) => field?.type === JourneyFormFieldType.TITLE)?.label ||
        ''
      : eventNameLabel
);

const selectBookedDescription = createSelector(
  selectBookDescription,
  selectRescheduleDescription,
  selectCancelDescription,
  selectIsRescheduled,
  selectEventIsCanceled,
  (bookDescription, rescheduleDescription, cancelDescription, isRescheduled, isCanceled) =>
    (isRescheduled && rescheduleDescription) || (isCanceled && cancelDescription) || bookDescription
);

const selectShowBackButton = createSelector(
  selectSteps,
  selectStep,
  selectGroupBookingPageId,
  selectIsLocationInPerson,
  (steps, step) => step !== EventSteps.BOOKED && Boolean(findPreviousStep(steps))
);
const selectShowRescheduleBackButton = createSelector(
  selectStep,
  selectIsExisting,
  (step, isExisting) => step === EventSteps.WHEN && isExisting
);
const selectShowNextButton = createSelector(
  selectSteps,
  selectStep,
  selectIsRescheduleMode,
  (steps, step, isRescheduleMode) =>
    !isRescheduleMode && step !== EventSteps.INFO && step !== EventSteps.BOOKED && Boolean(findNextStep(steps))
);
const selectShowBookButton = createSelector(
  selectStep,
  selectIsRescheduleMode,
  (step, isRescheduleMode) => step === EventSteps.INFO || (isRescheduleMode && step === EventSteps.WHEN)
);
const selectShowConfirmCancelButton = createSelector(selectStep, (step) => step === EventSteps.CANCEL);
const selectShowButtons = createSelector(
  selectShowBackButton,
  selectShowNextButton,
  selectShowBookButton,
  selectShowConfirmCancelButton,
  (showBackButton, showNextButton, showBookButton, showConfirmCancelButton) =>
    showBackButton || showNextButton || showBookButton || showConfirmCancelButton
);
const selectShowBookAnotherButton = createSelector(
  selectStep,
  selectBookAnotherEnabled,
  selectIsHostReschedule,
  (step, enabled, isHostReschedule) => !isHostReschedule && step === EventSteps.BOOKED && enabled
);
const selectShowCancelButton = createSelector(
  selectStep,
  selectCancelEnabled,
  selectEventIsCanceled,
  selectIsHostReschedule,
  selectIsEditKeyValid,
  selectIsPastEvent,
  (step, enabled, canceled, isHostReschedule, isEditKeyValid, isPastEvent) =>
    // TODO: show cancel button if isEditKeyValid=true or after event created (/book/ page and existing eventId)
    //  isEditKeyValid && !isPastEvent && !canceled && !isHostReschedule && step === EventSteps.BOOKED && enabled
    !isPastEvent && !canceled && !isHostReschedule && step === EventSteps.BOOKED && enabled
);
const selectShowDateTimeEditButton = createSelector(
  selectStep,
  selectRescheduleEnabled,
  selectEventIsCanceled,
  selectIsEditKeyValid,
  selectIsPastEvent,
  (step, enabled, canceled, isEditKeyValid, isPastEvent) =>
    !isPastEvent && isEditKeyValid && !canceled && (step === EventSteps.INFO || (step === EventSteps.BOOKED && enabled))
);
const selectShowInfoEditButton = createSelector(
  selectStep,
  selectRescheduleEnabled,
  selectEventIsCanceled,
  selectIsEditKeyValid,
  (step, enabled, canceled, isEditKeyValid) => isEditKeyValid && !canceled && enabled && step === EventSteps.BOOKED
);

const selectIsFieldsValid = createSelector(
  selectPersonalInputFields,
  selectFilteredInputFields,
  (personalFields, filteredFields) => [...personalFields, ...filteredFields].every(validateCustomField)
);

const selectIsSaveAvailable = createSelector(
  selectStartTime,
  selectEventLocationType,
  selectInPersonType,
  selectLocationAddress,
  selectIsFieldsValid,
  selectAdditionalGuestEmailsValidation,
  (startTime, locationType, inPersonType, address, isFieldsValid, additionalGuestEmailsValidation) =>
    startTime &&
    (locationType !== LocationType.IN_PERSON || inPersonType !== InPersonType.LOCATION || address) &&
    isFieldsValid &&
    additionalGuestEmailsValidation.every((isEmailValid) => isEmailValid)
);

const selectIsEditable = createSelector(
  selectIsPreviewMode,
  selectIsReadOnly,
  (isPreview, isReadOnly) => isPreview && !isReadOnly
);

const selectIsReRequestSlots = createSelector(
  selectStep,
  selectError,
  (step, error) => step === EventSteps.BOOKED || error
);

const selectLocationList = createSelector(selectAgenda, (agenda) => agenda.locations || []);
const selectSearchedLocations = createSelector(
  selectLocationList,
  globalSelectors.selectLowercasedSearchString,
  (locations, searchString) => {
    return locations.filter(
      (location) =>
        location.name?.toLowerCase().includes(searchString) ||
        location.address?.name?.toLowerCase().includes(searchString) ||
        location.address?.city?.toLowerCase().includes(searchString) ||
        location.address?.country?.toLowerCase().includes(searchString) ||
        location.address?.state?.toLowerCase().includes(searchString) ||
        location.address?.zip?.toLowerCase().includes(searchString)
    );
  }
);

const selectLocationsWithDistance = (userLocation: Coordinates | null) =>
  createSelector(selectSearchedLocations, (locations) =>
    userLocation
      ? locations
          .map(
            (location) =>
              ({
                ...location,
                ...getDistanceBetweenPointsMiKm(userLocation, {
                  lat: +(location.address?.lat || 0),
                  lng: +(location.address?.lng || 0),
                }),
              } as LocationWithDistance)
          )
          .sort((locationA, locationB) => (locationA.distanceMI > locationB.distanceMI ? 1 : -1))
      : []
  );

const selectNearestLocations = (userLocation: Coordinates | null) =>
  createSelector(selectLocationsWithDistance(userLocation), (locations) => {
    if (locations[0] && locations[0].distanceMI <= MAX_LOCATION_DISTANCE) {
      return locations.filter((location) => location.distanceMI <= MAX_LOCATION_DISTANCE);
    } else {
      return [];
    }
  });

export const eventSelectors = {
  selectEvent,
  selectAgenda,
  selectGoogleAnalytics,
  selectBookingPageId,
  selectBookedMeetingId,
  selectViewDate,
  selectStep,
  selectIsEditEvent,
  selectIsRescheduled,
  selectIsRescheduleMode,
  selectIsPreviewMode,
  selectIsReadOnly,
  selectIsHostReschedule,
  selectIsFetching,
  selectIsUpsertEventFetching,
  selectError,
  selectAgendaLoaded,
  selectViewSettings,
  selectGroupBookingPageId,
  selectChosenHostMemberId,
  selectIsSMSAllowed,
  selectIsExisting,
  selectEventId,
  selectEventDateString,
  selectEventDate,
  selectEventCancelPolicy,
  selectEventCanceled,
  selectEventIsCanceled,
  selectEventCanceledType,
  selectEventCanceledNote,
  selectStartTime,
  selectAttendeeName,
  selectAttendeeEmail,
  selectAttendeePhoneFormatted,
  selectAttendeeLocation,
  selectAttendeePhone,
  selectDefaultLocationType,
  selectLocationType,
  selectLocationTypeLabel,
  selectLocationSettings,
  selectLocationAddress,
  selectInPersonType,
  selectInputFields,
  selectPersonalInputFields,
  selectFilteredInputFields,
  selectAdditionalGuestEmails,
  selectNote,
  selectAdditionalGuestEmailsValidation,
  selectIsRedirect,
  selectExternalLink,
  selectCustomLinks,
  selectLocationTypeOptions,
  selectCustomPhone,
  selectCustomAddress,
  selectLocationOptions,
  selectIsLocationTypeEdit,
  selectShowLocationInput,
  selectShowHostPhone,
  selectShowCustomPhone,
  selectShowCustomAddress,
  selectShowLocationSettings,

  selectJourneyId,
  selectJourney,
  selectJourneySavedSteps,
  selectSavedLastStep,
  selectJourneyCurrentStep,
  selectJourneySelectedCard,
  selectIsJourneyBackAvailable,
  selectIsJourneyNextAvailable,

  selectGroupBookingPage,
  selectGroupBookingPages,

  selectBookingPage,
  selectDateRange,
  selectDuration,
  selectIsSMSEnabled,
  selectPageName,
  selectInviteOthers,
  selectEnterNote,
  selectLabels,
  selectEventNameLabel,
  selectBookButtonLabel,
  selectBookAnotherButtonLabel,
  selectBookTitle,
  selectBookDescription,
  selectRescheduleTitle,
  selectRescheduleDescription,
  selectCancelTitle,
  selectCancelDescription,
  selectCancelButtonLabel,
  selectConfirmCancelButtonLabel,
  selectCancelLabel,
  selectDetailsLabel,
  selectGuestsLabel,
  selectUpdateButtonLabel,

  selectStyle,
  selectLogo,
  selectBackground,
  selectBackgroundType,
  selectBackgroundColor,
  selectPrimaryColor,
  selectCss,
  selectFooterHtml,

  selectTimeFormat,
  selectIs12Hour,
  selectHasAdditionalOptions,
  selectHostPhone,
  selectHostDisplayName,
  selectCohosts,
  selectAllHosts,
  selectHostFullPhone,
  selectHostIsTeam,
  selectTimeZoneOptions,
  selectTimeZoneOptionsList,
  selectTimeZone,
  selectAvailableTimes,
  selectAvailableTimesByDate,
  selectDisabledDates,
  selectShowError,
  selectShowJourneyState,
  selectShowGroupState,
  selectShowPotentialHosts,
  selectShowLocations,
  selectShowDateTime,
  selectShowYourInfo,
  selectShowBookedState,
  selectShowCancel,
  selectShowBookingState,
  selectShowJourneyPageStep,
  selectShowJourneyDestinationStep,
  // selectShowRescheduledMessage,
  // selectShowCanceledMessage,
  // selectShowBookedMessage,
  selectPageTitle,
  selectBookedDescription,
  selectShowBackButton,
  selectShowNextButton,
  selectShowBookButton,
  selectShowRescheduleBackButton,
  selectShowButtons,
  selectShowConfirmCancelButton,
  selectShowBookAnotherButton,
  selectShowCancelButton,
  selectShowDateTimeEditButton,
  selectShowInfoEditButton,
  selectIsSaveAvailable,
  selectIsHideHeader,
  selectIsHideCookie,
  selectIsHidePageDetails,
  selectIsIframe,
  selectShowFooter,
  selectIsEditable,
  selectIsReRequestSlots,

  selectPotentialHosts,

  selectIsLocationInPerson,
  selectLocationList,
  selectSearchedLocations,
  selectLocationsWithDistance,
  selectNearestLocations,
  selectIsEditKeyValid,
};

import React, { useRef } from 'react';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { MultiSelect, MultiSelectPanelHeaderTemplateEvent, MultiSelectProps } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { UserAvatar } from '../userAvatar/UserAvatar';
import { SelectItem } from 'primereact/selectitem';
import { PhoneIcon, UsersIcon } from '@heroicons/react/24/outline';
import { ReactComponent as GoogleMeetIcon } from '../../../assets/icons/21-google-meet.svg';
import { ReactComponent as ZoomIcon } from '../../../assets/icons/21-zoom.svg';
import { ReactComponent as TeamsIcon } from '../../../assets/icons/21-teams.svg';
import labels from './labels';
import { HostOption } from '../../../types/types';
import { IntegrationType, PhysicalLocation, UpdateBookingPageInput } from '../../../API';
import { getFullAddress } from '../../../store/locations';
import { UserAdminDataFullRecord } from '../../../store/users/types';
import { MIN_ITEMS_FOR_SEARCH } from '../../../types/constants';

type SumoMultiSelectProps = MultiSelectProps & {
  onSelectAllChange: (checked: boolean) => void;
  templateType?: 'host' | 'user' | 'location' | 'workspace' | 'bookingPage';
  className?: string;
};

export const SumoMultiSelect: React.FC<SumoMultiSelectProps> = ({
  onSelectAllChange,
  templateType,
  className,
  ...props
}) => {
  const multiSelectRef = useRef<MultiSelect | null>(null);

  const headerTemplate = (e: MultiSelectPanelHeaderTemplateEvent) => (
    <div className="flex flex-column custom-multiselect-header -mb-4px">
      {(props.options?.length || 0) >= MIN_ITEMS_FOR_SEARCH && (
        <div className="border-bottom-1 border-heavy-10">{e.filterElement}</div>
      )}
      <div
        className="flex-left-center gap-12px px-12px py-10px hover-bg-heavy-1 border-radius-4px cursor-pointer"
        onClick={() => onSelectAllChange(!e.checked)}
      >
        <Checkbox checked={e.checked} className="pointer-events-none" />
        <div className="text-label-s-med text-heavy-50">{labels.selectAll}</div>
      </div>
    </div>
  );

  const getItemTemplate = (option: SelectItem) => (
    <div className="flex-left-center gap-8px -my-2px text-label-md-reg">
      <UserAvatar name={option.label || ''} />
      <div>{option.label}</div>
    </div>
  );

  const getLocationTemplate = (option: PhysicalLocation) => (
    <div className="flex flex-column gap-4px">
      <div className="text-label-md-reg text-heavy-80">{option.name}</div>
      <div className="text-label-xs-reg text-heavy-60">{getFullAddress(option.address)}</div>
    </div>
  );

  const getBookingPageTemplate = (option: UpdateBookingPageInput) => (
    <div className="flex-left-center gap-8px -my-2px text-label-md-reg">
      <div className="p-4px bg-heavy-1 border-radius-4px">
        <div className="w-16px h-16px border-radius-4px" style={{ backgroundColor: option.what?.color || '' }} />
      </div>
      <div>{option.what?.customName}</div>
    </div>
  );

  const getHostTemplate = (option: HostOption) => (
    <div className="flex-between-center">
      <div className="flex-left-center gap-8px -my-2px text-label-md-reg">
        {option.isTeam ? (
          <div className="p-avatar flex-none">
            <UsersIcon className="icon-16px" />
          </div>
        ) : (
          <UserAvatar name={option.label || ''} avatarLink={option.avatar} className="flex-none" />
        )}
        <div>{option.label}</div>
      </div>
      <div className="flex-none flex-left-center gap-2px">
        {option.hasPhoneNumber && (
          <div className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
            <PhoneIcon className="icon-12px text-heavy-60" />
          </div>
        )}
        {option.integrationTypes.map((type) => {
          switch (type) {
            case IntegrationType.GOOGLE_MEET:
              return (
                <div key={type} className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
                  <GoogleMeetIcon width={12} height={12} />
                </div>
              );
            case IntegrationType.MICROSOFT_TEAMS:
              return (
                <div key={type} className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
                  <TeamsIcon width={12} height={12} />
                </div>
              );
            case IntegrationType.ZOOM:
              return (
                <div key={type} className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
                  <ZoomIcon width={12} height={12} />
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );

  const getUserTemplate = (option: UserAdminDataFullRecord) => (
    <div className="flex-between-center">
      <div className="flex-left-center gap-8px -my-2px text-label-md-reg">
        <UserAvatar name={option.fullName || ''} avatarLink={option.avatar} className="flex-none" />
        <div>{option.fullName || option.email}</div>
      </div>
      <div className="flex-none flex-left-center gap-2px">
        {Boolean(option.phoneNumber) && (
          <div className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
            <PhoneIcon className="icon-12px text-heavy-60" />
          </div>
        )}
        {option.integrationTypes?.map((type) => {
          switch (type) {
            case IntegrationType.GOOGLE_MEET:
              return (
                <div key={type} className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
                  <GoogleMeetIcon width={12} height={12} />
                </div>
              );
            case IntegrationType.MICROSOFT_TEAMS:
              return (
                <div key={type} className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
                  <TeamsIcon width={12} height={12} />
                </div>
              );
            case IntegrationType.ZOOM:
              return (
                <div key={type} className="flex-center w-20px h-20px border-radius-4px bg-heavy-1">
                  <ZoomIcon width={12} height={12} />
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );

  const getTemplate = () => {
    switch (templateType) {
      case 'host':
        return getHostTemplate;
      case 'user':
        return getUserTemplate;
      case 'location':
        return getLocationTemplate;
      case 'bookingPage':
        return getBookingPageTemplate;
      case 'workspace':
      default:
        return getItemTemplate;
    }
  };

  return (
    <div className={`flex flex-column ${className}`}>
      <div
        className={`w-full add-button-lg ${props.disabled ? 'p-disabled' : ''}`}
        onClick={() => !props.disabled && multiSelectRef.current?.show()}
      >
        <PlusIcon className="icon-16px" />
      </div>
      <MultiSelect
        ref={multiSelectRef}
        options={props.options}
        value={props.value}
        onChange={props.onChange}
        filter={(props.options?.length || 0) >= MIN_ITEMS_FOR_SEARCH || props.filter}
        disabled={props.disabled}
        filterPlaceholder={props.filterPlaceholder}
        panelHeaderTemplate={headerTemplate}
        itemTemplate={getTemplate()}
        filterIcon={<MagnifyingGlassIcon className="icon-20px p-multiselect-filter-icon" />}
        closeIcon={null}
        className="w-full h-0 border-none opacity-0"
        emptyFilterMessage={labels.noResults}
        optionDisabled={props.optionDisabled}
        filterBy={props.filterBy}
      />
    </div>
  );
};

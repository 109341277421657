import { CallEffect, PutEffect, call, put } from 'redux-saga/effects';
import { googleAuth, microsoftAuth, signIn } from './service';
import {
  GetAuthResponseType,
  HandleMultipleAdminDataRecordsParams,
  MicrosoftCodeResponse,
  ThirdPartyAuthRequest,
  ThirdPartyLambdaResponse,
  ThirdPartyType,
  HandleAdminDataRecordsParams,
} from './types';
import { authenticationActions } from './actions';
import { THIRD_PARTY, INVITE_EXPIRED_ERROR_TOAST, EMPTY_SUMO1_ADMIN } from './constants';
import { CreateAdminDataInput } from '../../API';
import { UserSettingsKeys } from '../userSettings';
import { adminDataStatus } from '../../shared/backend/constants';
import { globalActions } from '../global';
import { notificationsActions } from '../notifications';

export function* getAuthResponse(
  props: GetAuthResponseType
): Generator<CallEffect<ThirdPartyLambdaResponse | void>, ThirdPartyLambdaResponse, ThirdPartyLambdaResponse> {
  const input = createAuthRequestBody(props);

  let response: ThirdPartyLambdaResponse;
  switch (props.type) {
    case ThirdPartyType.GOOGLE:
      response = yield call(googleAuth, input);
      break;
    case ThirdPartyType.MICROSOFT:
      response = yield call(microsoftAuth, input);
      break;
    default:
      throw new Error(`Unsupported third party type: ${props.type}`);
  }

  yield call(signIn, response);

  return response;
}

function createAuthRequestBody(props: GetAuthResponseType): ThirdPartyAuthRequest {
  const baseRequest: ThirdPartyAuthRequest = {
    integrationType: props.integrationType,
    code: props.codeResponse.code,
    switchAccount: props.switchAccount,
    login: props.login,
    userTimeZone: props.userTimeZone,
    updateIntegration: props.updateIntegration,
    joinTenant: props.joinTenant,
  };

  if (props.type === ThirdPartyType.MICROSOFT) {
    const redirectUrl = (props.codeResponse as MicrosoftCodeResponse).redirectUrl;
    return { ...baseRequest, redirectUrl };
  }

  return baseRequest;
}

export function* handleAuthResponse(
  authResponse: ThirdPartyLambdaResponse,
  payload: GetAuthResponseType
): Generator<PutEffect | CallEffect<void>, void, unknown> {
  const { type, switchAccount, updateIntegration, joinTenant, login } = payload;
  const { adminDataRecords, staffRecord, lastSuperAdmin, isNew, inviteExpired } = authResponse;

  // if invite expired - can be true only for invite link
  if (inviteExpired) {
    // to stop isFetching
    yield put(authenticationActions.thirdPartyAuthSuccess());
    // show invite expired popup
    yield put(notificationsActions.showToast(INVITE_EXPIRED_ERROR_TOAST));
  } else {
    // If there are no admin data records, throw an error
    if (!adminDataRecords) {
      throw new Error('User not found in the system');
    }

    // We update Third Party?
    if (!updateIntegration || switchAccount) {
      // SUMO1 Admin
      if (staffRecord) {
        yield put(authenticationActions.setSUMO1AdminData(staffRecord));
        localStorage.setItem(UserSettingsKeys.SUMO1_STAFF_DATA, JSON.stringify(staffRecord));
      } else {
        yield put(authenticationActions.setSUMO1AdminData(EMPTY_SUMO1_ADMIN));
        localStorage.removeItem(UserSettingsKeys.SUMO1_STAFF_DATA);
      }
      // whether the user is the last Super Admin in the current tenant with other users
      yield put(authenticationActions.setIsLastSuperAdmin(!!lastSuperAdmin));

      yield put(authenticationActions.setThirdParty(type));
      localStorage.setItem(THIRD_PARTY, type);
      // Handle the admin data records
      if (!switchAccount) {
        yield call(handleAdminDataRecords, { adminDataRecords, joinTenant, login, isNew });
      }
    }
  }
}

export function* handleAdminDataRecords(
  params: HandleAdminDataRecordsParams
): Generator<CallEffect<void> | PutEffect, void, unknown> {
  const { adminDataRecords, joinTenant, login, isNew } = params;

  if (adminDataRecords.length > 1) {
    // If the user has more than one admin data record, handle multiple admin data records
    yield call(handleMultipleAdminDataRecords, { adminDataRecords, joinTenant, login, isNew });
    // If everything is successful, dispatch the third party authentication success action
    // only for multiple invites (pop-up mode) to stop Preloader
    yield put(authenticationActions.thirdPartyAuthSuccess());
  } else {
    // If the user only has one admin data record, handle the single admin data record
    yield call(handleSingleAdminDataRecord, adminDataRecords[0]);
  }
}

export function* handleMultipleAdminDataRecords(
  params: HandleMultipleAdminDataRecordsParams
): Generator<CallEffect<void> | PutEffect, void, unknown> {
  const { adminDataRecords, joinTenant, login, isNew } = params;
  if (login) {
    // If the user is logging in, find the active admin data record and handle it
    const activeAdminData = adminDataRecords.find((record) => record.status === adminDataStatus.active);
    if (activeAdminData) {
      // If an active admin data record is found, handle it
      yield call(handleSingleAdminDataRecord, activeAdminData);
    } else {
      // If no active admin data record is found, choose first adminData
      yield call(handleSingleAdminDataRecord, adminDataRecords[0]);
    }
    // If the user has an invite tenant ID
  } else if (joinTenant) {
    // if one of the admin data records matches the invite tenant ID, handle the invited tenant
    yield call(handleInvitedTenant, adminDataRecords, joinTenant);
  } else if (adminDataRecords.length === 2 && !isNew) {
    // If the user has exactly two admin data records, handle the two admin data records, but if user is new we'll not show Leave Account popup
    yield call(handleTwoAdminDataRecords, adminDataRecords);
  } else {
    yield put(authenticationActions.setAccounts(adminDataRecords));
  }
}

function* handleTwoAdminDataRecords(adminDataRecords: CreateAdminDataInput[]): Generator<PutEffect, void, unknown> {
  // find newTenant and existing based on the adminData status
  const newTenant = adminDataRecords.find(
    (record) => record.invitedByName && record.status === adminDataStatus.inviteSent
  );
  const existingTenant = adminDataRecords.find(
    (record) => record.status === adminDataStatus.active || record.status === adminDataStatus.inactive
  );
  // If both the new tenant and the existing tenant exist, set the invite to account
  if (!!newTenant && !!existingTenant) {
    yield put(authenticationActions.setInviteToAccount({ newTenant, existingTenant }));
  } else {
    // If either the new tenant or the existing tenant does not exist, throw an error
    throw new Error('handleInvitedByEmailTenant(): tenant by email not found or active tenant is not found');
  }
}

function* handleInvitedTenant(
  adminDataRecords: CreateAdminDataInput[],
  inviteTenantId: string
): Generator<CallEffect<void> | PutEffect, void, unknown> {
  // Find the new tenant and the existing tenant based on the invite tenant ID
  const newTenant = adminDataRecords.find((record) => record.tenantId === inviteTenantId);
  const existingTenant = adminDataRecords.find(
    (record) => record.status === adminDataStatus.active || record.status === adminDataStatus.inactive
  );
  // If both the new tenant and the existing tenant exist, set the invite to account
  if (!!newTenant && !!existingTenant) {
    if (newTenant.tenantId === existingTenant.tenantId) {
      // if new tenant and existing are the same
      yield call(handleSingleAdminDataRecord, existingTenant);
    } else {
      yield put(authenticationActions.setInviteToAccount({ newTenant, existingTenant }));
    }
  } else {
    // If either the new tenant or the existing tenant does not exist, throw an error
    throw new Error('handleInvitedTenant(): tenantId is wrong or active tenant is not found');
  }
}

export function* handleSingleAdminDataRecord(
  adminDataRecord: CreateAdminDataInput
): Generator<PutEffect, void, unknown> {
  // Get the tenant ID from the admin data record
  const tenant = adminDataRecord.tenantId;
  // If the tenant ID exists, update the user data and check the license
  if (tenant) {
    // If everything is successful, dispatch the choose an account success action
    yield put(globalActions.getMainDataRequest());
    yield put(authenticationActions.chooseAnAccountSuccess());
  } else {
    // If the tenant ID does not exist, throw an error
    throw new Error('tenantId in chosen item in adminDataRecords was not found');
  }
}

export const Billing = Object.freeze({
  TITLE: `Facturación`,
  YOUR_SUBSCRIPTION: `Su Suscripción`,
  TEAMS_MONTHLY: `Equipos Mensual`,
  TEAMS_ANNUAL: `Equipos Anual`,
  SEATS: `asientos`,
  LAST_UPDATED: `Última actualización el`,
  CHANGE_TERM: `Cambiar Plazo`,
  ADD_SEATS: `Agregar Asientos`,
  REMOVE_SEATS: `Eliminar Asientos`,
  YOUR_NEXT_BILL: `Su Próxima Factura`,
  DUE_ON: `Vence el`,
  BILL_IS_LATE_PART_1: `La factura está actualmente`,
  BILL_IS_LATE_PART_2_SINGLE: `día atrasada.`,
  BILL_IS_LATE_PART_2_MULTIPLE: `días atrasada.`,
  BILLING_DETAILS: `Detalles de Facturación`,
  CHARGEBEE_BUTTON: `Abrir Portal de Facturación`,
  BILLING_PORTAL_DESRIPTION: `Puede ver su historial de transacciones y más.`,
  SCHEDULED_CHANGES: `Cambios Programados para`,
  SCHEDULED_CHANGES_DESRIPTION: `Los siguientes cambios han sido solicitados para tener lugar al final del término actual y pueden cancelarse antes del final del término.`,
  SCHEDULED_SEATS_CHANGE_PART_1: `Reducir`,
  SCHEDULED_SEATS_CHANGE_PART_2: `asientos`,
  SCHEDULED_TERM_CHANGE: `Plazo cambiado a`,
  SCHEDULED_COLUMN_LABEL: `Orden de Cambio`,
  SCHEDULED_COLUMN_CHANGE_BY: `Cambio por`,
  SCHEDULED_COLUMN_DATE: `Fecha de Solicitud`,
  SCHEDULED_COLUMN_OPTIONS: `Opciones`,
  ADD_SEATS_DESCRIPTION_PART_1: `Agregar`,
  ADD_SEATS_DESCRIPTION_PART_2: `asiento(s) aumentará`,
  ADD_SEATS_DESCRIPTION_PART_3: `su`,
  ADD_SEATS_DESCRIPTION_PART_4: `factura, comenzando`,
  ADD_SEATS_PRORATED_PART_1: `Para los`,
  ADD_SEATS_PRORATED_PART_2: `días restantes hasta su próximo pago, se cargará a su tarjeta de crédito un monto prorrateado de`,
  ADD_SEATS_PRORATED_PART_3: `hoy.`,
  REMOVE_SEATS_DESCRIPTION_PART_1: `Eliminar`,
  REMOVE_SEATS_DESCRIPTION_PART_2: `asiento(s) reducirá su`,
  REMOVE_SEATS_DESCRIPTION_PART_3: `factura en`,
  REMOVE_SEATS_DESCRIPTION_PART_4: `comenzando`,
  EXPIRE_MESSAGE_PART_1: `La factura está`,
  EXPIRE_MESSAGE_PART_2: `días atrasada. Su organización no será operativa en`,
  EXPIRE_MESSAGE_PART_3: `días.`,
  NUMBER_OF_LICENSES: `# Licencias`,
  LICENSES_TITLE: `licencias`,
  LICENSES_PLACEHOLDER: `Ingrese un número`,
  LICENSE_MESSAGE_PART_1: `Ya tiene`,
  LICENSE_MESSAGE_PART_2: `licencias asignadas a usuarios. Por favor, ingrese más`,
  LICENSE_MESSAGE_PART_3: `o desactive/elimine usuarios para liberar licencias`,
  LOADING_LABEL_1: `Conectando…`,
  LOADING_LABEL_2: `Agregando Licencias`,
  PAY_NOW_TITLE: `Pagar Ahora`,
  TRIAL_LICENSE_EXPIRED: `Su licencia de SUMO1 ha expirado. Para continuar utilizando SUMO1 sin interrupciones, por favor adquiera una nueva
  licencia.`,
  BUY_LICENSES_MESSAGE: `Su factura será`,
  MONTH_TITLE: `mes`,
  MONTHLY_TITLE: `mensual`,
  ANNUAL_TITLE: `anual`,
  ANNUALLY_TITLE: `anualmente`,
  REMOVE_SEATS_NOTE_PART1: `Para eliminar más de`,
  REMOVE_SEATS_NOTE_PART2: `asiento(s), puede desactivar o eliminar usuarios para liberar licencias.`,
  CANCELLATION_SCHEDULED: `Su suscripción está programada para cancelarse el `,
  ACCOUNT_SUSPENDED_PART1: `Su cuenta está suspendida. Debe`,
  ACCOUNT_SUSPENDED_PART2: `pagar su factura pendiente`,
  ACCOUNT_SUSPENDED_PART3: `para volver a habilitar el servicio.`,
});

export const BillingToastsNotifications = Object.freeze({
  GET_BILLING_ERROR_TOAST: `Error de Conexión con ChargeBee`,
  GET_ITEM_TIERS_ERROR_TOAST: `No se pudieron obtener los precios de los artículos de las licencias`,
  BUY_LICENSE_SUCCESS_TOAST: `Suscripción creada correctamente`,
  ADD_SEATS_SUCCESS_TOAST_TITLE: `Asientos añadidos.`,
  ADD_SEATS_SUCCESS_TOAST: `Espere hasta 1 minuto antes de que recibamos su pago`,
  ADD_SEATS_ERROR_TOAST: `No se pudieron agregar los asientos`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE: `Asientos eliminados.`,
  REMOVE_SEATS_SUCCESS_TOAST: `Debe desasignar los asientos antes del final del término.`,
  REMOVE_SEATS_ERROR_TOAST: `No se pudieron eliminar los asientos`,
  CHANGE_TERM_SUCCESS_TOAST: `Término cambiado con éxito.`,
  CHANGE_TERM_ERROR_TOAST: `No se pudo cambiar el término.`,
  CHARGEBEE_PORTAL_CLOSE_TOAST: `Espere hasta 1 minuto antes de que lo recibamos si ha realizado algún cambio, luego vuelva a abrir la página de facturación`,
  DELETE_CHANGE_SUCCESS_TOAST: `El cambio programado se eliminó correctamente.`,
  DELETE_CHANGE_ERROR_TOAST: `No se pudo eliminar el cambio programado.`,
  LIMIT_30_ITEMS: `Límite de 30 elementos`,
});

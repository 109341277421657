import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  convertAddressToGoogleMapLink,
  DEFAULT_FILTER,
  FILTER_STATUS_OPTIONS,
  LocationFilter,
  locationsActions,
  locationsSelectors,
} from '../../store/locations';
import { BulkActionRow, ConfirmationModal, SectionHeader, SumoTable } from '../../components/common';
import labels from './labels';
import { Path } from '../../routing';
import { useEffect, createRef, useState } from 'react';
import { Column } from 'primereact/column';
import { PhysicalLocation } from '../../API';
import { Menu } from 'primereact/menu';
import { EllipsisVerticalIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { MenuItem } from 'primereact/menuitem';
import { LocationsCloneModal } from '../../components/locations/locationsCloneModal/LocationsCloneModal';
import { AppDispatch } from '../../store/rootStore';
import { Button } from 'primereact/button';
import { navigationService } from '../../services/NavigationService';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { userSettingsSelectors } from '../../store/userSettings';

export const Locations = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isSpinnerFetching = useSelector(locationsSelectors.selectIsSpinnerFetching);
  const isSkeletonFetching = useSelector(locationsSelectors.selectIsSkeletonFetching);
  const locations = useSelector(locationsSelectors.selectLocations);
  const searchedLocations = useSelector(locationsSelectors.selectSearchedLocations);
  const location = useSelector(locationsSelectors.selectLocation);
  const name = useSelector(locationsSelectors.selectLocationName);
  const isFilterInUse = useSelector(locationsSelectors.selectIsFilterInUse);
  const filterStatus = useSelector(locationsSelectors.selectFilterStatus);
  const filterCountry = useSelector(locationsSelectors.selectFilterCountry);
  const filterState = useSelector(locationsSelectors.selectFilterState);
  const filterCity = useSelector(locationsSelectors.selectFilterCity);
  const filterCountryOptions = useSelector(locationsSelectors.selectFilterCountryOptions);
  const filterStateOptions = useSelector(locationsSelectors.selectFilterStateOptions);
  const filterCityOptions = useSelector(locationsSelectors.selectFilterCityOptions);
  const selectedLocations = useSelector(locationsSelectors.selectSelectedLocations);
  const selectedLocationIds = useSelector(locationsSelectors.selectSelectedLocationIds);

  const isCreate = useSelector(userSettingsSelectors.selectLocationsCreate);
  const isEdit = useSelector(userSettingsSelectors.selectLocationsEdit);
  const isDelete = useSelector(userSettingsSelectors.selectLocationsDelete);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);

  useEffect(() => {
    dispatch(locationsActions.getLocationsRequest());
  }, []);

  const handleCreateLocation = () => {
    navigationService.navigateTo(Path.EditLocation.replace(':locationId', 'new'));
  };

  const handleEdit = () => {
    navigationService.navigateTo(Path.EditLocation.replace(':locationId', location.id));
  };

  const handleClone = () => {
    dispatch(locationsActions.cloneLocationThunk());
  };

  const handleDeactivate = () => {
    setDeactivateModalOpen(true);
  };
  const deactivateModalClose = () => {
    setDeactivateModalOpen(false);
  };
  const deactivateModalConfirm = () => {
    dispatch(locationsActions.deactivateLocationRequest());
    deactivateModalClose();
  };

  const handleDelete = () => {
    setDeleteModalOpen(true);
  };
  const deleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const deleteModalConfirm = () => {
    dispatch(locationsActions.deleteLocationRequest(selectedLocationIds.length ? selectedLocationIds : [location.id]));
    deleteModalClose();
  };

  const handleImportCSV = () => {
    navigationService.navigateTo(Path.LocationsImport);
  };

  const itemTemplate: MenuItem['template'] = (item, options) => (
    <a className="p-menuitem-link" onClick={options.onClick}>
      <span className="mr-8px h-20px">{item.data}</span>
      <span className="p-menuitem-text">{item.label}</span>
    </a>
  );

  const menuItems: MenuItem[] = [
    {
      label: isEdit ? labels.edit : labels.view,
      // data: isEdit ? <PencilIcon width={20} height={20} /> : <EyeIcon width={20} height={20} />,
      template: itemTemplate,
      command: handleEdit,
    },
    {
      label: location.active ? labels.deactivate : labels.activate,
      // data: location.active ? <LockClosedIcon width={20} height={20} /> : <LockOpenIcon width={20} height={20} />,
      template: itemTemplate,
      command: location.active ? handleDeactivate : deactivateModalConfirm,
      visible: isEdit,
    },
    {
      label: labels.clone,
      // data: <DocumentDuplicateIcon width={20} height={20} />,
      template: itemTemplate,
      command: handleClone,

      visible: isCreate,
    },
    {
      label: labels.delete,
      // data: <TrashIcon width={20} height={20} />,
      template: itemTemplate,
      command: handleDelete,

      visible: isDelete,
    },
  ];

  const renderActions = (data: PhysicalLocation) => {
    const menu = createRef<Menu>();
    return (
      <div
        className="action-button"
        onClick={(e) => {
          dispatch(locationsActions.setLocation(data));
          menu.current?.toggle(e);
        }}
      >
        <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
        <EllipsisVerticalIcon width={20} height={20} />
      </div>
    );
  };

  const resetFilters = () => {
    dispatch(locationsActions.setFilter(DEFAULT_FILTER));
  };

  const handleFilterChange = (filterField: keyof LocationFilter, e: MultiSelectChangeEvent) => {
    dispatch(locationsActions.setFilter({ [filterField]: e.target.value }));
  };

  const handleBulkClose = () => {
    dispatch(locationsActions.setSelectedLocation([]));
  };

  const bulkContent = (
    <>
      {isDelete && (
        <Button text className="gap-8px text-button-md-med" onClick={handleDelete}>
          <TrashIcon className="icon-18px" />
          <div>{labels.delete}</div>
        </Button>
      )}
    </>
  );

  const handleLocationSelect = (value: PhysicalLocation[] | null) => {
    dispatch(locationsActions.setSelectedLocation(value ? value : []));
  };

  return (
    <div>
      <LocationsCloneModal />
      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          loading={isSpinnerFetching}
          title={labels.title}
          itemsLength={locations.length}
          searchPaths={[Path.Locations, Path.LocationsImport, Path.EditLocation]}
          buttonLabel={labels.addLabel}
          onButtonClick={handleCreateLocation}
          hideButton={!isCreate}
          filters={[
            {
              label: labels.city,
              value: filterCity,
              options: filterCityOptions,
              onChange: (e) => handleFilterChange('city', e),
            },
            {
              label: labels.state,
              value: filterState,
              options: filterStateOptions,
              onChange: (e) => handleFilterChange('state', e),
            },
            {
              label: labels.country,
              value: filterCountry,
              options: filterCountryOptions,
              onChange: (e) => handleFilterChange('country', e),
            },
            {
              label: labels.status,
              value: filterStatus,
              options: FILTER_STATUS_OPTIONS,
              onChange: (e) => handleFilterChange('status', e),
            },
          ]}
          onFilterReset={resetFilters}
          isFilterActive={isFilterInUse}
          extraContent={
            isCreate ? (
              <Button className="flex-none button-xl" onClick={handleImportCSV} outlined>
                <PlusIcon width={18} />
                <span className="flex-1 pl-8px">{labels.importCSV}</span>
              </Button>
            ) : undefined
          }
        />
        <div className={`w-full ${selectedLocations.length ? 'mb-36px' : ''}`}>
          <SumoTable
            loading={isSkeletonFetching}
            emptyDescription={labels.emptyListDesc}
            value={searchedLocations}
            actionsBody={renderActions}
            removableSort={false}
            bulkActions={true}
            selectionMode="checkbox"
            selectionAutoFocus={false}
            selection={selectedLocations}
            onSelectionChange={(e) => handleLocationSelect(e.value as PhysicalLocation[])}
            dataKey="id"
          >
            <Column field="name" header={labels.name} sortable bodyClassName="text-overflow-ellipsis max-w-150px" />
            <Column
              field="address.name"
              header={labels.address}
              sortable
              bodyClassName="text-overflow-ellipsis max-w-90px"
              body={(data: PhysicalLocation) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={data.address ? convertAddressToGoogleMapLink(data.address) : ''}
                >
                  {data.address?.name}
                </a>
              )}
            />
            <Column
              field="address.city"
              header={labels.city}
              sortable
              bodyClassName="text-overflow-ellipsis max-w-90px"
            />
            <Column
              field="address.zip"
              header={labels.zip}
              sortable
              bodyClassName="text-overflow-ellipsis max-w-90px"
            />
            <Column
              field="address.state"
              header={labels.state}
              sortable
              bodyClassName="text-overflow-ellipsis max-w-90px"
            />
            <Column
              field="address.country"
              header={labels.country}
              sortable
              bodyClassName="text-overflow-ellipsis max-w-90px"
            />
            <Column
              field="active"
              body={(data: PhysicalLocation) => (
                <div className={`text-label-s-med flex-center ${data.active ? 'active-badge' : 'inactive-badge'} `}>
                  {data.active ? labels.active : labels.inactive}
                </div>
              )}
              header={labels.status}
              sortable
              bodyClassName="text-overflow-ellipsis max-w-60px"
              frozen
            />
          </SumoTable>
        </div>
      </div>
      <BulkActionRow
        selectedCount={selectedLocations.length}
        handleClose={handleBulkClose}
        actionsContent={bulkContent}
      />
      <ConfirmationModal
        title={labels.deactivateTitle}
        description={`${labels.deactivateDescription} "${name}"?`}
        visible={deactivateModalOpen}
        onClose={deactivateModalClose}
        onCancel={deactivateModalClose}
        onConfirm={deactivateModalConfirm}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
      <ConfirmationModal
        title={labels.deleteTitle}
        description={`${labels.deleteDescription} ${
          selectedLocationIds.length ? `${selectedLocationIds.length} ${labels.selectedLocations}` : `"${name}"`
        }?`}
        visible={deleteModalOpen}
        onClose={deleteModalClose}
        onCancel={deleteModalClose}
        onConfirm={deleteModalConfirm}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
    </div>
  );
};

import { memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteBookingTemplatesModalActions, bookingTemplatesActions } from '../../../store/bookingTemplates';
import { Path } from '../../../routing';
import labels from './labels';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { LocationType, UpdateBookingTemplateInput } from '../../../API';
import { navigationService } from '../../../services/NavigationService';
import { userSettingsSelectors } from '../../../store/userSettings';
import { AppDispatch } from '../../../store/rootStore';
import { EllipsisVerticalIcon, PhoneIcon, UserIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { getTimeUnitLabel } from '../../../services/utils';
import { Checkbox } from 'primereact/checkbox';
import { InformationBlock } from '../../common';

interface IBookingTemplateCard {
  bookingTemplate: UpdateBookingTemplateInput;
  selectMode: boolean;
  selected: boolean;
  hostsInfo: string;
  handleCheckboxChange: (id: string, checked: boolean | undefined) => void;
}

export const BookingTemplateCard = memo(
  ({ bookingTemplate, selectMode, selected, hostsInfo, handleCheckboxChange }: IBookingTemplateCard) => {
    const { id, enabled, what, where, when } = bookingTemplate;
    const dispatch = useDispatch<AppDispatch>();
    const menu = useRef<Menu>(null);
    const isBookingTemplatesCreate = useSelector(userSettingsSelectors.selectBookingTemplatesCreate);
    const isBookingTemplatesEdit = useSelector(userSettingsSelectors.selectBookingTemplatesEdit);
    const isBookingTemplatesDelete = useSelector(userSettingsSelectors.selectBookingTemplatesDelete);

    let blockCardClick = false;

    const handleCardClick = () => {
      if (blockCardClick) {
        blockCardClick = false;
        return;
      }
      selectMode ? handleCheckboxChange(id, !selected) : handleEdit();
    };

    const handleEdit = () => {
      dispatch(bookingTemplatesActions.setBookingTemplate(bookingTemplate));
      navigationService.navigateTo(Path.EditBookingTemplate.replace(':bookingTemplateId', id));
    };

    const handleEnable = () => {
      dispatch(
        bookingTemplatesActions.enableBookingTemplateRequest({
          ...bookingTemplate,
          enabled: !enabled,
        })
      );
    };

    const handleEnableButton = () => {
      blockCardClick = true;
      handleEnable();
    };

    const handleClone = () => {
      dispatch(bookingTemplatesActions.setBookingTemplate(bookingTemplate));
      dispatch(bookingTemplatesActions.cloneBookingTemplateThunk());
    };

    const handleDelete = () => {
      dispatch(bookingTemplatesActions.selectBookingTemplate(id));
      dispatch(deleteBookingTemplatesModalActions.openModal());
    };

    const menuItems = [
      { label: labels.edit, command: handleEdit, visible: isBookingTemplatesEdit },
      {
        label: enabled ? labels.deactivate : labels.activate,
        command: handleEnable,
        visible: isBookingTemplatesEdit && !!hostsInfo,
      },
      { label: labels.clone, command: handleClone, visible: isBookingTemplatesCreate && !!hostsInfo },
      { label: labels.delete, command: handleDelete, visible: isBookingTemplatesDelete },
    ];

    const generateLocationTypeIcon = (locationType: LocationType | null) => {
      switch (locationType) {
        case LocationType.IN_PERSON:
          return <UserIcon width={16} height={16} />;
        case LocationType.PHONE_CALL:
          return <PhoneIcon width={16} height={16} />;
        case LocationType.VIDEO_CONFERENCE:
          return <VideoCameraIcon width={16} height={16} />;
        default:
          return <></>;
      }
    };

    return (
      <div
        className={`sumo-card action-card flex flex-column h-full ${selected ? 'selected' : ''} ${
          !enabled ? 'sumo-card-inactive' : ''
        }`}
        onClick={handleCardClick}
      >
        <div className="flex pt-22px px-18px">
          <div className="flex-1 text-title-lg-med">{what?.customName}</div>
          <div className="flex-center gap-4px -my-1px h-24px">
            {!isBookingTemplatesEdit && (
              <div className="text-label-s-med text-heavy-60 bg-heavy-10 border-radius-6px py-6px px-8px">
                {labels.locked}
              </div>
            )}
            <Checkbox
              checked={selected}
              onChange={(e) => {
                e.stopPropagation();
                handleCheckboxChange(id, !!e.checked);
              }}
              className={`card-selector ${selectMode ? '' : 'card-selector-hidden'}`}
            />
            {(isBookingTemplatesCreate || isBookingTemplatesEdit || isBookingTemplatesDelete) && (
              <div
                className="-my-4px -mr-16px action-button"
                onClick={(e) => {
                  blockCardClick = true;
                  menu.current?.toggle(e);
                }}
                style={{ visibility: selectMode ? 'hidden' : 'visible' }}
              >
                <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
                <EllipsisVerticalIcon className="icon-20px" />
              </div>
            )}
          </div>
        </div>

        <div className="pt-4px px-18px text-body-s-reg text-heavy-60">
          {`${when?.duration?.count} ${getTimeUnitLabel(when?.duration?.timeUnit)}`}
        </div>

        <div className="flex flex-wrap py-12px px-18px">
          {hostsInfo ? (
            <div className="text-label-s-med text-heavy-80 bg-heavy-1 border-radius-6px py-10px px-8px">
              {hostsInfo}
            </div>
          ) : (
            <InformationBlock text={labels.unassigned} />
          )}
        </div>

        <div className="flex-1 card-delimeter" />

        <div className="flex justify-content-between align-items-center p-10px pl-18px">
          <div className="flex gap-4px">
            <div className="p-8px bg-heavy-1 border-radius-6px">
              <div className="w-16px h-16px border-radius-4px" style={{ backgroundColor: what?.color || '' }} />
            </div>
            {where?.locationTypes?.map((locationType) => (
              <div key={locationType} className="flex-center w-32px h-32px bg-heavy-1 border-radius-6px">
                {generateLocationTypeIcon(locationType)}
              </div>
            ))}
          </div>

          <div className="flex align-items-center gap-8px h-38px">
            {enabled ? (
              <Button className="button-blue card-button" label={labels.viewDetails} text disabled={selectMode} />
            ) : (
              !enabled &&
              isBookingTemplatesEdit &&
              !!hostsInfo && (
                <Button
                  label={labels.activate}
                  outlined
                  severity="success"
                  onClick={handleEnableButton}
                  disabled={selectMode}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  }
);

BookingTemplateCard.displayName = 'BookingTemplateCard';

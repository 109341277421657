import { AppType, IntegrationType, WorkspaceIntegrationType } from '../../../API';
import { Button } from 'primereact/button';
import { integrationIcon, integrationLabels } from '../../../store/integration';
import labels from './labels';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { CodeResponse } from '@react-oauth/google';
import { MicrosoftCodeResponse, authenticationSelectors } from '../../../store/authentication';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ReactComponent as SumoIcon } from '../../../assets/images/sumo.svg';
import background from '../../../assets/images/sumoistBackground.svg';
import { DefaultVideoConference } from '../../quickSetup';
import { generateVideoConference } from '../../../store/integration/utils';

type ConnectIntegrationProps = {
  integrationType: IntegrationType | WorkspaceIntegrationType | AppType;
  handleDisconnect?: () => void;
  ConnectButton?: React.ElementType;
  CustomSettingsSection?: React.ReactElement;
  hasCustomSettingsChanges?: boolean;
  buttonUpdateIsDisabled?: boolean;
  handleUpdate?: () => void;
  handleAuthSuccess?: (codeResponse: CodeResponse) => void;
  handleAuthRedirect?: (codeResponse: MicrosoftCodeResponse) => void;
  IntegrationIcon?: React.ReactElement;
};

export const ConnectIntegration: React.FC<ConnectIntegrationProps> = ({
  integrationType,
  handleDisconnect,
  ConnectButton,
  CustomSettingsSection,
  hasCustomSettingsChanges,
  buttonUpdateIsDisabled,
  handleUpdate,
  handleAuthSuccess,
  handleAuthRedirect,
  IntegrationIcon,
}) => {
  const isPersonalIntegrationConnected = useSelector(
    userSettingsSelectors.selectIsIntegrationConnected(integrationType)
  );
  const isWorkspaceIntegrationConnected = useSelector(
    authenticationSelectors.selectIsWorkspaceIntegrationConnected(integrationType)
  );
  const videoConferenceType = generateVideoConference(integrationType);
  const isDefaultVideoConference = isPersonalIntegrationConnected && videoConferenceType;
  const isConnected = isPersonalIntegrationConnected || isWorkspaceIntegrationConnected;
  const isWorkspaceOrSuperAdmin = useSelector(userSettingsSelectors.selectIsWorkspaceOrSuperAdmin);
  const isWorkspaceIntegration = Object.values(WorkspaceIntegrationType).includes(
    integrationType as WorkspaceIntegrationType
  );
  const buttonIsDisabled = isWorkspaceIntegration && !isWorkspaceOrSuperAdmin;

  return (
    <div className="sumo-card border-none flex flex-column gap-16px h-full py-24px px-20px">
      <div className="flex gap-40px">
        <div
          className="flex-center gap-12px h-fit px-48px py-40px border-1 border-radius-14px border-blue-soft bg-cover bg-center"
          style={{ backgroundImage: `url(${background})` }}
        >
          {IntegrationIcon || (
            <>
              <SumoIcon className="w-56px h-56px" />
              <PlusIcon width={16} height={16} className="text-blue-main" />
              <img src={integrationIcon[integrationType as keyof typeof integrationIcon]} className="w-56px h-56px" />
            </>
          )}
        </div>
        <div className="flex-1">
          <div className="text-title-xs-med mb-4px">{labels.benefitsTitle}</div>
          <div className="text-body-s-reg text-heavy-60 mb-24px">
            {labels.benefitsDesc[integrationType as keyof typeof integrationLabels].includes('/p') ? (
              <ul className="pl-22px">
                {labels.benefitsDesc[integrationType as keyof typeof integrationLabels]
                  .split('/p')
                  .map((line, index) => (
                    <li key={index}>{line}</li>
                  ))}
              </ul>
            ) : (
              labels.benefitsDesc[integrationType as keyof typeof integrationLabels]
                .split('\n')
                .map((line, index) => <div key={index}>{line}</div>)
            )}
          </div>
          <div className="text-title-xs-med mb-4px">{labels.requirementsTitle}</div>
          <div className="text-body-s-reg text-heavy-60">
            {labels.requirementsDesc[integrationType as keyof typeof integrationLabels]
              .split('\n')
              .map((line, index) => (
                <div key={index}>{line}</div>
              ))}
          </div>
          {CustomSettingsSection}
        </div>
      </div>

      <div className="flex-between-center">
        <div>
          {isDefaultVideoConference && <DefaultVideoConference videoConferenceType={videoConferenceType} hasLabel />}
        </div>
        <div>
          {isConnected && hasCustomSettingsChanges && (
            <Button
              className="button-xl mr-20px"
              label={labels.update}
              onClick={handleUpdate}
              disabled={buttonUpdateIsDisabled}
            />
          )}
          {isConnected && (
            <Button
              className="button-xl"
              outlined
              label={labels.disconnect}
              onClick={handleDisconnect}
              disabled={buttonIsDisabled}
            />
          )}
          {!isConnected && ConnectButton && (
            <ConnectButton
              label={labels.connect}
              className="button-xl"
              hideIcon
              onSuccess={handleAuthSuccess}
              onRedirect={handleAuthRedirect}
              disabled={buttonIsDisabled}
            />
          )}
        </div>
      </div>
    </div>
  );
};

import { memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Path } from '../../../routing';
import {
  addToWebsiteModalActions,
  bookingPagesActions,
  bookingPageSelectors,
  deleteBookingPagesModalActions,
} from '../../../store/bookingPages';
import labels from './labels';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { LocationType, UpdateBookingPageInput, UpdateBookingTemplateInput } from '../../../API';
import { navigationService } from '../../../services/NavigationService';
import { getTimeUnitLabel } from '../../../services/utils';
import { getUrlOrigin } from '../../../services/URLService';
import { AppDispatch } from '../../../store/rootStore';
import { EllipsisVerticalIcon, LinkIcon, PhoneIcon, UserIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { MenuItem } from 'primereact/menuitem';
import { Checkbox } from 'primereact/checkbox';
import { InformationBlock } from '../../common';

interface IBookingPageCard {
  bookingPage: UpdateBookingPageInput;
  selectMode: boolean;
  selected: boolean;
  hostsInfo: string;
  template?: UpdateBookingTemplateInput;
  handleCheckboxChange: (id: string, checked: boolean | undefined) => void;
}

export const BookingPageCard = memo(
  ({ bookingPage, selectMode, selected, hostsInfo, template, handleCheckboxChange }: IBookingPageCard) => {
    const { id, shortLink, what, when } = bookingPage;
    const dispatch = useDispatch<AppDispatch>();
    const menu = useRef<Menu>(null);

    const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked(bookingPage));
    const isBookingPageCreate = useSelector(bookingPageSelectors.selectIsBookingPageCreate(bookingPage));
    const isBookingPageEdit = useSelector(bookingPageSelectors.selectIsBookingPageEdit(bookingPage));
    const isBookingPageDelete = useSelector(bookingPageSelectors.selectIsBookingPageDelete(bookingPage));

    let blockCardClick = false;

    // uncomment to show link copied message when it's ready
    // const [linkCopied, setLinkCopied] = useState(false);
    // useEffect(() => {
    //   linkCopied && setTimeout(() => setLinkCopied(false), 3000);
    // }, [linkCopied]);

    const handleCardClick = () => {
      if (blockCardClick) {
        blockCardClick = false;
        return;
      }
      selectMode ? handleCheckboxChange(id, !selected) : handleEdit();
    };

    const handleEdit = () => {
      dispatch(bookingPagesActions.setBookingPage(bookingPage));
      navigationService.navigateTo(Path.EditBookingPage.replace(':bookingPageId', id));
    };

    const handleActivate = () => {
      dispatch(
        bookingPagesActions.activateBookingPageRequest({
          ...bookingPage,
          what: { ...bookingPage.what, isActive: !what?.isActive },
        })
      );
    };

    const handleActivateButton = () => {
      blockCardClick = true;
      handleActivate();
    };

    const handleClone = () => {
      dispatch(bookingPagesActions.setBookingPage(bookingPage));
      dispatch(bookingPagesActions.cloneBookingPageThunk());
    };

    const handleDelete = () => {
      dispatch(bookingPagesActions.selectBookingPage(id));
      dispatch(deleteBookingPagesModalActions.openModal());
    };

    const handleCopyLink = () => {
      blockCardClick = true;
      const bookingPageUrl = shortLink
        ? shortLink
        : getUrlOrigin() + Path.PublicBookingPage.replace(':bookingPageId', id);
      navigator.clipboard.writeText(bookingPageUrl);
      // setLinkCopied(true);
    };

    const handleAddToWebsite = () => {
      blockCardClick = true;
      dispatch(bookingPagesActions.setBookingPage(bookingPage));
      dispatch(bookingPagesActions.setAddToWebsiteLink(Path.PublicBookingPage.replace(':bookingPageId', id)));
      dispatch(addToWebsiteModalActions.openModal());
    };

    const menuItems: MenuItem[] = [
      { visible: isBookingPageEdit, label: labels.edit, command: handleEdit },
      {
        visible: isBookingPageEdit && !!hostsInfo,
        label: what?.isActive ? labels.deactivate : labels.activate,
        command: handleActivate,
      },
      { visible: isBookingPageCreate && !!hostsInfo, label: labels.clone, command: handleClone },
      { visible: isBookingPageDelete, label: labels.delete, command: handleDelete },
    ];

    const generateLocationTypeIcon = (locationType: LocationType | null) => {
      switch (locationType) {
        case LocationType.IN_PERSON:
          return <UserIcon width={16} height={16} />;
        case LocationType.PHONE_CALL:
          return <PhoneIcon width={16} height={16} />;
        case LocationType.VIDEO_CONFERENCE:
          return <VideoCameraIcon width={16} height={16} />;
        default:
          return <></>;
      }
    };

    return (
      <div
        className={`sumo-card action-card flex flex-column h-full ${selected ? 'selected' : ''} ${
          !what?.isActive ? 'sumo-card-inactive' : ''
        }`}
        onClick={handleCardClick}
      >
        <div className="flex pt-22px px-18px">
          <div className="flex-1 text-title-lg-med">{bookingPage.what?.customName}</div>
          <div className="flex-center gap-4px -my-1px h-24px">
            {isBookingPageLocked && (
              <div className="text-label-s-med text-heavy-60 bg-heavy-10 border-radius-6px py-6px px-8px">
                {labels.locked}
              </div>
            )}
            <Checkbox
              checked={selected}
              onChange={(e) => {
                e.stopPropagation();
                handleCheckboxChange(id, !!e.checked);
              }}
              className={`card-selector ${selectMode ? '' : 'card-selector-hidden'}`}
            />
            {(isBookingPageCreate || isBookingPageEdit || isBookingPageDelete) && (
              <div
                className="-my-4px -mr-16px action-button"
                onClick={(e) => {
                  blockCardClick = true;
                  menu.current?.toggle(e);
                }}
                style={{ visibility: selectMode ? 'hidden' : 'visible' }}
              >
                <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
                <EllipsisVerticalIcon className="icon-20px" />
              </div>
            )}
          </div>
        </div>

        <div className="flex-left-center pt-4px px-18px text-body-s-reg text-heavy-60">
          <div>{bookingPage.displayId}</div>
          <div className="w-4px h-4px border-radius-2px mx-6px bg-heavy-50" />
          <div>{`${when?.duration?.count} ${getTimeUnitLabel(when?.duration?.timeUnit)}`}</div>
          {template?.what?.customName && (
            <>
              <div className="w-4px h-4px border-radius-2px mx-6px bg-heavy-50" />
              <div>{template?.what?.customName}</div>
            </>
          )}
        </div>

        <div className="flex flex-wrap py-12px px-18px">
          {hostsInfo ? (
            <div className="text-label-s-med text-heavy-80 bg-heavy-1 border-radius-6px py-10px px-8px">
              {hostsInfo}
            </div>
          ) : (
            <InformationBlock text={labels.unassigned} />
          )}
        </div>

        <div className="card-delimeter flex-1" />

        <div className="flex justify-content-between align-items-center p-10px pl-18px">
          <div className="flex gap-4px">
            <div className="p-8px bg-heavy-1 border-radius-6px">
              <div className="w-16px h-16px border-radius-4px" style={{ backgroundColor: what?.color || '' }} />
            </div>
            {bookingPage.where?.locationTypes?.map((locationType) => (
              <div key={locationType} className="flex-center w-32px h-32px bg-heavy-1 border-radius-6px">
                {generateLocationTypeIcon(locationType)}
              </div>
            ))}
          </div>

          <div className="flex align-items-center gap-8px h-38px">
            {what?.isActive ? (
              <>
                <Button className="button-icon" outlined onClick={handleCopyLink} disabled={selectMode}>
                  <LinkIcon className="icon-18px" />
                </Button>
                <Button label={labels.addToSite} outlined onClick={handleAddToWebsite} disabled={selectMode} />
              </>
            ) : (
              !what?.isActive &&
              isBookingPageEdit &&
              !!hostsInfo && (
                <Button
                  label={labels.activate}
                  outlined
                  severity="success"
                  onClick={handleActivateButton}
                  disabled={selectMode}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  }
);

BookingPageCard.displayName = 'BookingPageCard';

import { SmartAlert, SmartAlertTemplate } from '../../../API';
import { getCardDesc, getCardIcon } from '../../../store/smartAlerts/utils';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { EllipsisVerticalIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { MenuItem } from 'primereact/menuitem';
import labels from './labels';
import { Checkbox } from 'primereact/checkbox';
import { useSelector } from 'react-redux';
import {
  DeleteSmartTypeModalRequest,
  deleteSmartTypesModalActions,
  SelectedAlertsType,
  smartAlertsActions,
  smartAlertsSelectors,
} from '../../../store/smartAlerts';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/rootStore';
import { Path } from '../../../routing';
import { navigationService } from '../../../services/NavigationService';
import { userSettingsSelectors } from '../../../store/userSettings';

type SmartAlertCardProps = {
  alert?: SmartAlert;
  template?: SmartAlertTemplate;
  useTemplate?: () => void;
  useAlert?: () => void;
};
export const SmartAlertCard = ({ alert, template, useTemplate, useAlert }: SmartAlertCardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedAlerts: SelectedAlertsType[] = useSelector(smartAlertsSelectors.selectSelectedAlerts);
  const isSelected = useSelector(smartAlertsSelectors.selectIsSelectedAlert(alert?.id || template?.id || ''));
  const isReadOnly = useSelector(
    smartAlertsSelectors.selectIsAlertReadOnly(alert?.id || template?.id || '', alert ? 'alert' : 'template')
  );
  const isDeleteLocked = useSelector(
    smartAlertsSelectors.selectIsAlertDeleteLocked(alert?.id || template?.id || '', alert ? 'alert' : 'template')
  );
  const isCreateAlert = useSelector(userSettingsSelectors.selectSmartAlertsCreate);
  const isCreateTemplate = useSelector(userSettingsSelectors.selectSmartAlertTemplatesCreate);

  const Icon = getCardIcon(alert?.icon || template?.icon || '');
  const menu = useRef<Menu>(null);

  let blockCardClick = false;

  const handleEdit = () => {
    if (blockCardClick) {
      blockCardClick = false;
      return;
    }

    if (alert) {
      dispatch(smartAlertsActions.setSmartAlert(alert));
    } else if (template) {
      dispatch(smartAlertsActions.setSmartAlertTemplate(template));
    }

    navigationService.navigateTo(
      Path.SmartAlert.replace(':smartType', alert ? 'alert' : 'template').replace(
        ':smartAlertId',
        alert?.id || template?.id || ''
      )
    );
  };

  const handleClone = () => {
    if (alert) {
      dispatch(smartAlertsActions.setSmartAlert(alert));
      dispatch(smartAlertsActions.cloneSmartAlertThunk());
    } else if (template) {
      dispatch(smartAlertsActions.setSmartAlertTemplate(template));
      dispatch(smartAlertsActions.cloneSmartAlertTemplateThunk());
    }
  };

  const handleDelete = () => {
    if (alert) {
      dispatch(
        deleteSmartTypesModalActions.openModal({
          selectedAlerts: [{ type: 'alert', id: alert.id }],
          name: alert.name,
        } as DeleteSmartTypeModalRequest)
      );
    } else if (template) {
      dispatch(
        deleteSmartTypesModalActions.openModal({
          selectedAlerts: [{ type: 'template', id: template.id }],
          name: template.name,
        } as DeleteSmartTypeModalRequest)
      );
    }
  };

  const handleSelectAlert = (checked: boolean) => {
    if (alert) {
      const alerts = checked
        ? [...selectedAlerts, { type: 'alert', id: alert.id } as SelectedAlertsType]
        : selectedAlerts.filter((item) => item.id !== alert.id);
      dispatch(smartAlertsActions.selectAlerts(alerts));
    } else if (template) {
      const templates = checked
        ? [...selectedAlerts, { type: 'template', id: template.id } as SelectedAlertsType]
        : selectedAlerts.filter((item) => item.id !== template.id);
      dispatch(smartAlertsActions.selectAlerts(templates));
    }
  };

  const itemTemplate: MenuItem['template'] = (item, options) => (
    <a className="p-menuitem-link" onClick={options.onClick}>
      <span className="mr-8px h-20px">{item.data}</span>
      <span className="p-menuitem-text">{item.label}</span>
    </a>
  );

  const menuItems: MenuItem[] = [
    {
      label: labels.edit,
      command: handleEdit,
      // data: <PencilIcon width={20} height={20} />,
      template: itemTemplate,
      visible: !isReadOnly,
    },
    {
      label: labels.view,
      command: handleEdit,
      // data: <EyeIcon width={20} height={20} />,
      template: itemTemplate,
      visible: isReadOnly,
    },
    {
      label: labels.clone,
      command: handleClone,
      // data: <DocumentDuplicateIcon width={20} height={20} />,
      template: itemTemplate,
      visible: alert ? isCreateAlert : isCreateTemplate,
    },
    {
      label: labels.delete,
      command: handleDelete,
      // data: <TrashIcon width={20} height={20} />,
      template: itemTemplate,
      visible: !isDeleteLocked,
    },
  ];

  const handleCardClick = () => {
    if (selectedAlerts.length) {
      handleSelectAlert(!isSelected);
    } else if (useTemplate) {
      useTemplate();
    } else if (useAlert) {
      useAlert();
    } else {
      handleEdit();
    }
  };

  return (
    <div
      className={`sumo-card action-card flex flex-column h-full p-20px pb-12px ${isSelected ? 'selected' : ''}`}
      onClick={handleCardClick}
    >
      <div className="flex">
        <div className="p-4px h-56px w-56px mr-auto ">
          <Icon width={48} height={48} />
        </div>
        {useTemplate || useAlert ? (
          <div className="card-element-hidden text-button-md-med">
            {useTemplate ? labels.useTemplate : labels.useAlert}
          </div>
        ) : (
          <>
            <Checkbox
              checked={isSelected}
              onChange={(e) => {
                e.stopPropagation();
                handleSelectAlert(!!e.checked);
              }}
              className={`card-selector ${selectedAlerts.length ? '' : 'card-selector-hidden'} mr-4px`}
            />
            <div
              className="-my-4px -mr-16px action-button"
              onClick={(e) => {
                blockCardClick = true;
                menu.current?.toggle(e);
              }}
              style={{ visibility: selectedAlerts.length ? 'hidden' : 'visible' }}
            >
              <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
              <EllipsisVerticalIcon width={20} height={20} />
            </div>
          </>
        )}
      </div>
      <div className="flex-left-center mt-16px gap-4px">
        {isReadOnly && <LockClosedIcon width={16} height={16} />}
        <div className="text-title-lg-med">{alert?.name || template?.name}</div>
      </div>

      <div className="text-card-s-med text-heavy-60 mt-6px">
        {template ? template.description || '' : getCardDesc(alert?.whenSend, alert?.whomSend)}
      </div>
    </div>
  );
};
